import { useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { sortByDefault } from '../../utils/filtering';
import {
  clearReportPresalesEmployeeAction,
  getReportPresalesEmployeeAction,
} from '../../store/actionCreators/reportsActionCreators';
import { RootState } from '../../store';
import { useRowExpanded } from './hooks/useRowExpanded';
import { groupProjectsByEmployee } from './helpers/groupProjectsByEmployee';
import { preparePresalesEmployeeReportTableData } from './helpers/preparePresalesEmployeeReportTableData';
import { EmployeeRow, UseReportPresalesEmployeeReturn } from './types';
import useGetPresalesEmployeeReport from '../../hooks/useGetPresalesEmployeeReport';

const expandedRowStyle = { color: '#171717', fontWeight: 400, fontSize: 14 };

export const useReportPresalesEmployee =
  (): UseReportPresalesEmployeeReturn => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const tableRef = useRef<HTMLTableElement>(null);
    const { endDate, billable, startDate } = useParams();

    const { expandByDefault, setExpandedRowId } = useRowExpanded();

    const { reportPresalesEmployee } = useAppSelector(
      (state: RootState) => state,
    );

    const { isLoading } = useGetPresalesEmployeeReport({
      clearReportAction: () =>
        dispatch(clearReportPresalesEmployeeAction(null)),
      getReportAction: (data) =>
        dispatch(getReportPresalesEmployeeAction(data)),
    });

    const groupedByEmployee = useMemo(
      () =>
        groupProjectsByEmployee(
          reportPresalesEmployee.projectCountHoursDtoList ?? [],
        ),
      [reportPresalesEmployee.projectCountHoursDtoList],
    );

    const presalesEmployeeReportTableData = useMemo(
      () => preparePresalesEmployeeReportTableData(groupedByEmployee),
      [groupedByEmployee],
    );

    const goToEmployee = (row: EmployeeRow) =>
      navigate(
        `../by-presales/presale${row.original.projectNameAndId.id}/employee${row.original.employeeFullName.id}/start${startDate}/end${endDate}/accrual${billable}`,
      );

    const sortedPresalesEmployeeReportTableData = useMemo(
      () =>
        presalesEmployeeReportTableData.sort((a, b) =>
          sortByDefault(a, b, 'name'),
        ),
      [presalesEmployeeReportTableData],
    );

    return {
      expandByDefault,
      expandedRowStyle,
      goToEmployee,
      isLoading,
      setExpandedRowId,
      sortedPresalesEmployeeReportTableData,
      t,
      tableRef,
    };
  };
