import { toast } from 'react-toastify';
import {
  CURRENT,
  handleError,
  INFO,
  makeRequest,
  PM_TOOL,
  PROJECTS,
  USERS,
} from './config';
import {
  PageProjectWebDto,
  ProjectAssignedClientDto,
  ProjectWebDto,
  TeamMemberDto,
  UserFullDto,
} from './api-types';
import { CreateProject } from '../validation/project';

export const getRegisteredEmployee = async () => {
  try {
    const url = `${USERS}${CURRENT}`;
    const response = await makeRequest.get<UserFullDto>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

type GetProject = {
  sortParam?: string;
  page?: number;
  size?: number;
  pmId?: string;
  status?: boolean;
  unpaged?: boolean;
  isPresale?: boolean | null;
};

export const getProject = async ({
  page,
  size,
  unpaged,
  sortParam,
  pmId,
  status,
  isPresale,
}: GetProject) => {
  const pageParam = page ?? 0;
  const sizeParam = size ?? 50;
  const unpagedParam = unpaged ?? false;
  const sort = sortParam ?? '';
  try {
    const url = `${PM_TOOL}${PROJECTS}`;
    const response = await makeRequest.get<PageProjectWebDto>(url, {
      params: {
        page: pageParam,
        size: sizeParam,
        unpaged: unpagedParam,
        isPresale,
        sort,
        ...(pmId !== undefined ? { pmId } : {}),
        ...(status !== undefined ? { status } : {}),
      },
    });
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

export const getProjectById = async (id: number) => {
  try {
    const url = `${PM_TOOL}${PROJECTS}/${id}`;
    const response = await makeRequest.get<ProjectWebDto>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

type PostProject = {
  data: CreateProject;
};

export const postProject = async ({ data }: PostProject) => {
  try {
    const url = `${PM_TOOL}${PROJECTS}`;
    const response = await makeRequest.post<ProjectAssignedClientDto>(
      url,
      data,
    );
    toast.success('New project was added successfully');
    return response.data;
  } catch (error) {
    handleError(error);
    return null;
  }
};

type EditedProjectRequest = {
  description: string;
  endDate: string | undefined;
  billableType: string;
  projectManagerId: number | null;
  projectName: string;
  startDate: string | undefined;
};

type PutProject = {
  id: number;
  data: EditedProjectRequest;
};

export const putProject = async ({ id, data }: PutProject) => {
  try {
    const url = `${PM_TOOL}${PROJECTS}/${id}`;
    const response = await makeRequest.put<ProjectAssignedClientDto>(url, data);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

export const getProjectInfo = async () => {
  try {
    const url = `${PROJECTS}${INFO}`;
    const response = await makeRequest.get<TeamMemberDto>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};
