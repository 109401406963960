import {
  CLIENT,
  ONLY_TOTAL,
  PRESALE,
  PROJECTS,
  PROJECT_MANAGER,
  REPORTS,
  SWITCHERS,
  handleError,
  makeRequest,
} from './config';
import {
  SwitcherRequest,
  ProjectReport,
  ProjectManagerEmployeeReport,
  ProjectEmployeeReport,
  TotalReportDto,
  ProjectManagerReportItem,
  PresalesAllReportDTO,
} from './api-types';

export const getSwitcher = async () => {
  try {
    const url = `${SWITCHERS}`;
    const response = await makeRequest.get<boolean>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

type ApplySwitcher = {
  data: SwitcherRequest;
};

export const applySwitcher = async ({ data }: ApplySwitcher) => {
  try {
    const query = `${SWITCHERS}`;
    const url = `${query}`;
    await makeRequest.post(url, data);
  } catch (e) {
    handleError(e);
  }
};

type GetReportByProject = {
  projectId: number;
  startDate: string;
  endDate: string;
  billableTypes: string;
  employeeTaskName?: string;
};

export const getBackendReportByProject = async ({
  billableTypes,
  endDate,
  startDate,
  projectId,
  employeeTaskName,
}: GetReportByProject) => {
  try {
    const url = `${REPORTS}${PROJECTS}/${projectId}`;
    const response = await makeRequest.get<ProjectReport>(url, {
      params: {
        startDate: startDate,
        endDate: endDate,
        billableTypes: billableTypes,
        ...(employeeTaskName ? { tasks: employeeTaskName } : {}),
      },
    });
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

type GetReportByPM = {
  managerId: number;
  startDate: string;
  endDate: string;
  billableTypes: string;
};

export const getBackendReportByPM = async ({
  managerId,
  startDate,
  endDate,
  billableTypes,
}: GetReportByPM) => {
  try {
    const query = `?endPeriod=${endDate}&startPeriod=${startDate}&billableTypes=${billableTypes}&pmId=${managerId}`;
    const url = `${REPORTS}${PROJECT_MANAGER}/${query}`;
    const response = await makeRequest.get<ProjectManagerReportItem[]>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

export const getBackendReportByPMAll = async ({
  startDate,
  endDate,
  billableTypes,
}: Omit<GetReportByPM, 'managerId'>) => {
  try {
    const query = `?endPeriod=${endDate}&startPeriod=${startDate}&billableTypes=${billableTypes}`;
    const url = `${REPORTS}${PROJECT_MANAGER}${query}`;
    const response = await makeRequest.get<ProjectManagerReportItem[]>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

type GetReportByEmployee = {
  projectId: number;
  employeeId: number;
  startDate: string;
  endDate: string;
  billableTypes: string;
  employeeTaskName?: string;
};

export const getBackendReportByEmployee = async ({
  billableTypes,
  employeeId,
  endDate,
  projectId,
  startDate,
  employeeTaskName,
}: GetReportByEmployee) => {
  try {
    const url = `${REPORTS}${PROJECTS}/${projectId}/employees/${employeeId}`;
    const response = await makeRequest.get<ProjectEmployeeReport>(url, {
      params: {
        startPeriod: startDate,
        endPeriod: endDate,
        billableTypes: billableTypes,
        ...(employeeTaskName ? { tasks: employeeTaskName } : {}),
      },
    });
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

export const getPresaleAllReport = async ({
  startDate,
  endDate,
}: {
  startDate: string | undefined;
  endDate: string | undefined;
}) => {
  try {
    const query = `?endPeriod=${endDate}&startPeriod=${startDate}`;
    const url = `${REPORTS}${PRESALE}${query}`;
    const response = await makeRequest.get<PresalesAllReportDTO>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

export const getAllPresalesEmployeeReport = async ({
  startDate,
  endDate,
  employeeId,
}: {
  startDate: string | undefined;
  endDate: string | undefined;
  employeeId: number | undefined;
}) => {
  try {
    const query = `&endPeriod=${endDate}&startPeriod=${startDate}`;
    const url = `${REPORTS}${PRESALE}?employeeId=${employeeId}${query}`;
    const response = await makeRequest.get<PresalesAllReportDTO>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

type GetReportByPMEmployee = {
  projectManagerId: number;
  employeeId: number;
  startDate: string;
  endDate: string;
  billableTypes: string;
};

export const getBackendReportByPMEmployee = async ({
  billableTypes,
  employeeId,
  endDate,
  projectManagerId,
  startDate,
}: GetReportByPMEmployee) => {
  try {
    const query = `/${projectManagerId}/employees/${employeeId}?startPeriod=${startDate}&endPeriod=${endDate}&billableTypes=${billableTypes}`;
    const url = `${REPORTS}${PROJECT_MANAGER}${query}`;
    const response = await makeRequest.get<ProjectManagerEmployeeReport>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

type GetReportClient = {
  clientId?: number;
  employeeId?: number;
  endDate: string;
  billableTypes: string;
  startDate: string;
};

export const getBackendReportClient = async ({
  billableTypes,
  endDate,
  startDate,
  clientId,
  employeeId,
}: GetReportClient) => {
  try {
    const query = `?${clientId ? `clientId=${clientId}&` : ''}${
      employeeId ? `employeeId=${employeeId}&` : ''
    }endPeriod=${endDate}&billableTypes=${billableTypes}&startPeriod=${startDate}`;
    const url = `${REPORTS}${CLIENT}/${query}`;
    const response = await makeRequest.get<TotalReportDto>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};

type GetReportOnlyTotal = {
  clientId?: number;
  department?: string;
  employeeId?: number;
  endDate: string;
  billableTypes: string;
  startDate: string;
};

export const getBackendReportOnlyTotal = async ({
  billableTypes,
  department,
  endDate,
  startDate,
  clientId,
  employeeId,
}: GetReportOnlyTotal) => {
  try {
    const query = `?${clientId ? `clientId=${clientId}&` : ''}${
      department && department !== 'All' ? `department=${department}&` : ''
    }${
      employeeId ? `employeeId=${employeeId}&` : ''
    }endPeriod=${endDate}&billableTypes=${billableTypes}&startPeriod=${startDate}`;
    const url = `${REPORTS}${ONLY_TOTAL}/${query}`;
    const response = await makeRequest.get<TotalReportDto>(url);
    return response.data;
  } catch (e) {
    handleError(e);
    return null;
  }
};
