import { Outlet, useNavigate } from 'react-router-dom';
import { reportType } from '../../constants/select-values';
import { controlsListingStyle } from '../../components/Select/selectStyles';
import commonStyles from '../../styles/pm-flow.module.scss';
import { getReports } from '../../utils/reports';
import SimpleSelect from '../../components/Select/SimpleSelect';
import HeaderBlock from '../../modules/Reports/HeaderBlock/HeaderBlock';
import { clearedSelectedEmployee } from '../../constants/cleared-objects';
import ToggleSlider from '../../components/ToggleSlider/ToggleSlider';
import FilterPanelBlock from '../../modules/Reports/FilterPanelBlock/FilterPanelBlock';
import Button from '../../components/Button/Button';
import SubHeaderBlock from '../../modules/Reports/SubHeaderBlock/SubHeaderBlock';
import { useReportsPageLogic } from './useReportsPageLogic';

const ReportsPage = () => {
  const navigate = useNavigate();

  const {
    accrualTypeFilter,
    clientId,
    clientName,
    customerNames,
    departmentId,
    departmentList,
    departmentName,
    employeeId,
    employeeTaskName,
    employeesByRole,
    end,
    handleSwitcher,
    isOn,
    memberList,
    openModalToAddTimeLog,
    pmList,
    pmMemberList,
    projectId,
    projectListAll,
    projectName,
    reportClient,
    reportEndDate,
    reportPM,
    reportPMEmployee,
    reportProject,
    reportProjectEmployee,
    reportStartDate,
    reportTotal,
    setHandleValues,
    start,
    teamMemberName,
    typeOfReport,
    userId,
    userName,
    t,
    isPresaleReport,
  } = useReportsPageLogic();

  return (
    <>
      <div className={commonStyles.header}>
        <HeaderBlock
          {...{
            reportClient,
            reportPM,
            reportPMEmployee,
            reportProject,
            reportProjectEmployee,
            reportTotal,
          }}
        />

        <div className={commonStyles.switchingWrapper}>
          <SimpleSelect
            selectWrapperClass='select_vertical_wrapper'
            selectTitleClassName='select_title'
            selectTitle={t('reports.types.title') as string}
            options={reportType(t)}
            value={{
              label: typeOfReport,
              value: typeOfReport,
            }}
            onChange={(e) => {
              setHandleValues({
                type: 'SET_SELECTABLES',
                payload: { typeOfReport: e.value },
              });
              setHandleValues({
                type: 'CLEAR_TEXT',
                payload: clearedSelectedEmployee,
              });
            }}
            styles={controlsListingStyle}
          />

          <ToggleSlider
            key='key-project-3'
            isOn={isOn}
            type='checkbox'
            className='slider-checkbox'
            id='switcher'
            handleToggle={handleSwitcher}
            toggleSliderTitle={t('buttons.flexible_time_tracking') as string}
          />

          <Button
            styleClass='btn__rounded-white'
            label={t('buttons.log_employee_time') as string}
            onClick={openModalToAddTimeLog}
          />
        </div>
      </div>

      <div className={commonStyles.header}>
        <FilterPanelBlock
          {...{
            accrualTypeFilter,
            clientId,
            clientName,
            customerNames,
            departmentId,
            departmentList,
            departmentName,
            employeeId,
            employeeTaskName,
            employeesByRole,
            memberList,
            pmList,
            pmMemberList,
            projectId,
            projectListAll,
            projectName,
            reportEndDate,
            reportStartDate,
            setHandleValues,
            teamMemberName,
            typeOfReport,
            userId,
            userName,
            isPresaleReport,
          }}
          getReportsByCase={getReports({
            accrualTypeFilter,
            clientId,
            departmentName,
            employeeId,
            employeeTaskName,
            end,
            navigate,
            projectId,
            start,
            t,
            typeOfReport,
            userId,
          })}
        />
      </div>

      <div className={commonStyles.header}>
        <SubHeaderBlock
          {...{
            reportClient,
            reportPMEmployee,
            reportProject,
            reportProjectEmployee,
            reportTotal,
          }}
        />
      </div>

      <div className={commonStyles.report}>
        <Outlet />
      </div>
    </>
  );
};

export default ReportsPage;
