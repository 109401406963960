import { selectPMModalMain } from '../../../components/Select/selectStyles';
import styles from '../../../components/Modal/styles/project-modal.module.scss';
import dataPairStyles from '../../../components/DataPair/data-pair.module.scss';
import SimpleSelect from '../../../components/Select/SimpleSelect';
import DataPair from '../../..//components/DataPair/DataPair';

import { FC } from 'react';
import { EditCustomerSelectProps } from './types';
import { useEditCustomerSelect } from './useEditCustomerSelect';
import useFilteredCustomers from './hooks/useFilteredCustomers';
import { useSelectedCustomerValue } from './hooks/useSelectedCustomerValue';

const EditCustomerSelect: FC<EditCustomerSelectProps> = ({
  projectState,
  setProjectState,
  isPresale,
}) => {
  const { onCustomerChange, t } = useEditCustomerSelect({
    projectState,
    setProjectState,
  });

  const filteredCustomers = useFilteredCustomers();

  const selectedCustomerValue = useSelectedCustomerValue({
    filteredCustomers,
    projectState,
  });

  return (
    <>
      <div className={styles.project_data}>
        <DataPair
          dataPairTitle={dataPairStyles.data_pair_title}
          title={`${t('modals.fields.customer')}${!isPresale ? '*' : ''}`}
        />
        <div className={styles.data_input}>
          <SimpleSelect
            options={filteredCustomers}
            onChange={(e) => onCustomerChange(e.value)}
            value={selectedCustomerValue}
            styles={selectPMModalMain}
          />
        </div>
      </div>
    </>
  );
};

export default EditCustomerSelect;
