import React from 'react';
import { useTranslation } from 'react-i18next';

import commonStyles from '../../styles/pm-flow.module.scss';
import { controlsListingStyle } from '../../components/Select/selectStyles';

import { fetchEmployeesByRole } from '../../api/team';
import { getAllCustomersLocations } from '../../api/customers';

import ControlsPanel from '../../components/ControlsPanel/ControlsPanel';
import CreateProjectModal from '../../components/Modal/CreateProjectModal/CreateProjectModal';
import Loader from '../../components/Loader/Loader';
import SimpleSelect from '../../components/Select/SimpleSelect';
import Table from '../../components/Tables/Table';
import TablePagination from '../../components/TablePagination/TablePagination';

import { clearedSelectedProject } from '../../constants/cleared-objects';

import { useFetchData } from './hooks/useFetchData';
import { useProjectControls } from './hooks/useProjectControls';
import { useProjectData } from './hooks/useProjectData';
import { useProjectListPage } from './useProjectListPage';
import { useTableSetup } from './hooks/useTableSetup';
import { ACCRUAL_FILTER_TYPE } from '../../translations/accrualTypeFilterEN';

const ProjectListPage = ({ isPresale }: { isPresale: boolean | null }) => {
  const { t } = useTranslation();

  const handledClearedSelectedProject = isPresale
    ? {
        ...clearedSelectedProject,
        billableType: ACCRUAL_FILTER_TYPE.INTERNAL_BILLABLE,
        isPresale: true,
      }
    : { ...clearedSelectedProject, isPresale: false };

  const {
    currentPage,
    dispatch,
    getFilterdProjectList,
    goToItemDetails,
    isLoading,
    locationList,
    pmList,
    projectId,
    projectList,
    projectListAll,
    projectListAllSorted,
    projectName,
    registeredEmployee,
    selectedPMId,
    selectedPMName,
    selectedStatus,
    setCurrentPage,
    setHandleValues,
    setIsLoading,
    setPageCurrentData,
    sortParam,
    toggleAddProject,
    toggleSortParam,
    totalAmount,
  } = useProjectListPage({ isPresale });

  const {
    clearSelectedProject,
    createProject,
    getAllProjects,
    onSelectProject,
    updateProjectsList,
  } = useProjectData({
    currentPage,
    dispatch,
    selectedPMId,
    selectedStatus,
    setHandleValues,
    setIsLoading,
    setPageCurrentData,
    sortParam,
    toggleAddProject,
    isPresale,
  });

  useFetchData({
    currentPage,
    dispatch,
    fetchEmployeesByRole,
    getAllCustomersLocations,
    getAllProjects,
    projectListAll,
    selectedPMId,
    selectedStatus,
    sortParam,
    updateProjectsList,
  });

  const { projectColumns, tableRef, tableInstance } = useTableSetup({
    projectList,
    isPresale,
  });

  const { controlsProjectLeft, controlsProjectRight } = useProjectControls({
    clearSelectedProject,
    pmList,
    projectId,
    projectListAllSorted,
    registeredEmployee,
    selectedPMId,
    selectedPMName,
    selectedStatus,
    setCurrentPage,
    setHandleValues,
    toggleAddProject,
    totalAmount,
    isPresale,
  });

  return (
    <div className={commonStyles.containerMinWidth}>
      <div className={commonStyles.header}>
        <h2>{isPresale ? t('presales.title') : t('projects.title')}</h2>

        <SimpleSelect
          key='key-report-3'
          selectWrapperClass='select_vertical_wrapper'
          selectTitleClassName='select_title'
          selectTitle={
            isPresale
              ? String(t('presales.select_presale'))
              : String(t('projects.select_project'))
          }
          options={[
            {
              id: null,
              label: t('projects.listing.value.0'),
              value: t('projects.listing.value.0'),
            },
            ...getFilterdProjectList().map((project) => ({
              id: project.id,
              label: project.projectName,
              value: project.projectName,
            })),
          ]}
          value={{
            id: projectId || 'select',
            label: projectName || 'Select...',
            value: projectName || 'Select...',
          }}
          onChange={onSelectProject}
          styles={controlsListingStyle}
        />
      </div>

      <div className={commonStyles.container}>
        <ControlsPanel
          controlsLeftBlock={controlsProjectLeft}
          controlsRightBlock={controlsProjectRight}
        />
        {projectList.length !== 0 && !isLoading ? (
          <>
            <Table
              //TODO: resolve props issue
              //@ts-ignore
              columns={projectColumns}
              data={projectList}
              onRowClick={goToItemDetails}
              onToggleSortParam={toggleSortParam}
              ref={tableRef}
              sortByApiRequest
              tableInstance={tableInstance}
              tableName='pm-projects-list'
            />
            <TablePagination
              currentPage={currentPage}
              rowsPerPage={20}
              setCurrentPage={setCurrentPage}
              totalRows={totalAmount}
            />
          </>
        ) : (
          <p className={commonStyles.noItems}>
            {t('warning.no_projects_data')}
          </p>
        )}
      </div>

      {toggleAddProject && (
        <CreateProjectModal
          customerLocationList={locationList}
          data={handledClearedSelectedProject}
          modalTitle={
            isPresale
              ? t('modals.titles.new_presale')
              : t('modals.titles.new_project')
          }
          onSave={createProject}
          onClose={() =>
            setHandleValues({
              type: 'ON_TOGGLE_VIEW',
              payload: { toggleAddProject: !toggleAddProject },
            })
          }
          projectManagerList={pmList}
        />
      )}

      {isLoading && <Loader />}
    </div>
  );
};

export default ProjectListPage;
