/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CcpEmailRequestDto,
  CustomerGetDto,
  CustomerLocationDto,
  CustomerProjectsDto,
  CustomerRequestDto,
  DepartmentDto,
  EmployeeTask,
  MonthLogs,
  PageEmployeeProjectTaskDto,
  PageNameIdDto,
  PageProjectWebDto,
  PresalesAllReportDTO,
  ProjectEmployeeReport,
  ProjectLastUsedDto,
  ProjectManagerEmployeeReport,
  ProjectManagerReportItem,
  ProjectReport,
  ProjectWebDto,
  SicknessesDto,
  TeamMemberDto,
  TotalReportDto,
  UnitDto,
  UserFullDto,
  UserNameIdDto,
  VacationList,
} from '../api/api-types';
import { msgWithPayload } from '../utils/msg';

export type Msg = ReturnType<
  | typeof ADD_CLIENTS_CONTACT_FIELD
  | typeof ADD_CLIENTS_EMAIL_FIELD
  | typeof ADD_CLIENTS_LOCATION_FIELD
  | typeof CHANGE_CLIENTS_UNIT_FIELD
  | typeof CLEAR_CLIENT_DATA
  | typeof CLEAR_REPORT_CLIENT
  | typeof CLEAR_REPORT_PM
  | typeof CLEAR_REPORT_PM_ALL
  | typeof CLEAR_REPORT_PM_EMPLOYEE
  | typeof CLEAR_REPORT_PROJECT
  | typeof CLEAR_REPORT_PROJECT_EMPLOYEE
  | typeof CLEAR_REPORT_TOTAL
  | typeof CLEAR_TEAM_MEMBERS
  | typeof GET_CLIENTS
  | typeof GET_CLIENTS_ALL
  | typeof GET_CLIENT_DATA
  | typeof GET_DEPARTMENTS
  | typeof GET_EMPLOYEES
  | typeof GET_EMPLOYEES_BY_ROLE
  | typeof GET_EMPLOYEE_DATA
  | typeof GET_LOCATIONS
  | typeof GET_MEMBER_ID
  | typeof GET_PM
  | typeof GET_PM_TEAM
  | typeof GET_PROJECTS
  | typeof GET_PROJECTS_ALL
  | typeof GET_PROJECTS_BY_CLIENT_ID
  | typeof GET_PROJECTS_INFO
  | typeof GET_PROJECT_ID
  | typeof GET_REGISTERED_EMPLOYEE
  | typeof GET_REPORT_CLIENT
  | typeof GET_REPORT_PM
  | typeof GET_REPORT_PM_EMPLOYEE
  | typeof GET_REPORT_PM_ALL
  | typeof GET_REPORT_PROJECT
  | typeof GET_REPORT_PROJECT_EMPLOYEE
  | typeof GET_REPORT_TOTAL
  | typeof GET_SICKNESS_DATA
  | typeof GET_TASKS
  | typeof GET_TASK_ID
  | typeof GET_TEAM
  | typeof GET_TIME_TRACKED_DATA
  | typeof GET_UNITS
  | typeof GET_VACATION_DATA
  | typeof REMOVE_CLIENTS_EMAIL_FIELD
  | typeof REMOVE_CLIENTS_LOCATION_FIELD
  | typeof RESET_CLIENTS_FIELD
  | typeof SET_CLIENTS_DETAILS
  | typeof UPDATE_CLIENT_PAGE
  | typeof SET_CLIENTS_LOCATION_FIELD
  | typeof UPDATE_PROJECT
  | typeof CLEAR_TEXT
  | typeof EDIT_MODE
  | typeof ON_TOGGLE_VIEW
  | typeof SELECT_DATA
  | typeof SET_CONFIRM_POP_UP
  | typeof SET_CURRENT_PAGE
  | typeof SET_DATE_VALUE
  | typeof SET_DATE_VALUES_ARRAY
  | typeof SET_EDIT_MODE
  | typeof SET_END_DATE
  | typeof SET_FILE
  | typeof SET_FILE_NAME
  | typeof SET_ID
  | typeof SET_IS_POP_UP_APPROVED
  | typeof SET_LOADER
  | typeof SET_NAVIGATE_URL
  | typeof SET_NEXT_PAGE
  | typeof SET_PAGE_DATA
  | typeof SET_PREVIOUS_PAGE
  | typeof SET_SELECTABLES
  | typeof SET_SELECTED_PAGE
  | typeof SET_START_DATE
  | typeof SET_TEXT
  | typeof SET_WARNING_POP_UP
  | typeof SET_KEY
  | typeof SET_BCOIN_IMAGES
  | typeof GET_REPORT_PRESALES_ALL
  | typeof CLEAR_REPORT_PRESALES_ALL
  | typeof GET_REPORT_PRESALES_EMPLOYEE
  | typeof CLEAR_REPORT_PRESALES_EMPLOYEE
>;

export const GET_REPORT_PRESALES_EMPLOYEE = msgWithPayload<{
  payload: PresalesAllReportDTO;
}>()('GET_REPORT_PRESALES_EMPLOYEE');

export const CLEAR_REPORT_PRESALES_EMPLOYEE = msgWithPayload<{
  payload: null;
}>()('CLEAR_REPORT_PRESALES_EMPLOYEE');

export const ADD_CLIENTS_CONTACT_FIELD = msgWithPayload<{
  payload: {
    //TODO: this should be CustomerContactPersonRequestDto, but reducer logic seems to be incorrect
    field: any;
  };
}>()('ADD_CLIENTS_CONTACT_FIELD');
export const ADD_CLIENTS_EMAIL_FIELD = msgWithPayload<{
  payload: {
    field: CcpEmailRequestDto;
    parentIndx: number;
  };
}>()('ADD_CLIENTS_EMAIL_FIELD');
export const ADD_CLIENTS_LOCATION_FIELD = msgWithPayload<{
  payload: {
    name: string;
  };
}>()('ADD_CLIENTS_LOCATION_FIELD');
export const CHANGE_CLIENTS_UNIT_FIELD = msgWithPayload<{
  payload: {
    id: string | undefined;
    value: string | undefined;
  };
}>()('CHANGE_CLIENTS_UNIT_FIELD');
export const CLEAR_CLIENT_DATA = msgWithPayload<{
  payload: CustomerGetDto | null;
}>()('CLEAR_CLIENT_DATA');
export const CLEAR_REPORT_CLIENT = msgWithPayload<{
  payload: TotalReportDto;
}>()('CLEAR_REPORT_CLIENT');
export const CLEAR_REPORT_PM = msgWithPayload<{
  payload: ProjectManagerReportItem[] | null;
}>()('CLEAR_REPORT_PM');

export const CLEAR_REPORT_PM_ALL = msgWithPayload<{
  payload: ProjectManagerReportItem[] | null;
}>()('CLEAR_REPORT_PM_ALL');

export const CLEAR_REPORT_PM_EMPLOYEE = msgWithPayload<{
  payload: ProjectManagerEmployeeReport;
}>()('CLEAR_REPORT_PM_EMPLOYEE');
export const CLEAR_REPORT_PROJECT = msgWithPayload<{
  payload: ProjectReport;
}>()('CLEAR_REPORT_PROJECT');
export const CLEAR_REPORT_PROJECT_EMPLOYEE = msgWithPayload<{
  payload: ProjectEmployeeReport;
}>()('CLEAR_REPORT_PROJECT_EMPLOYEE');
export const CLEAR_REPORT_TOTAL = msgWithPayload<{ payload: null }>()(
  'CLEAR_REPORT_TOTAL',
);
export const CLEAR_TEAM_MEMBERS = msgWithPayload<{
  payload: PageEmployeeProjectTaskDto['content'];
}>()('CLEAR_TEAM_MEMBERS');
export const GET_CLIENTS = msgWithPayload<{
  payload: PageNameIdDto['content'];
}>()('GET_CLIENTS');
export const GET_CLIENTS_ALL = msgWithPayload<{
  payload: PageNameIdDto['content'];
}>()('GET_CLIENTS_ALL');
export const GET_CLIENT_DATA = msgWithPayload<{ payload: CustomerGetDto }>()(
  'GET_CLIENT_DATA',
);
export const GET_DEPARTMENTS = msgWithPayload<{ payload: DepartmentDto[] }>()(
  'GET_DEPARTMENTS',
);
export const GET_EMPLOYEES = msgWithPayload<{ payload: UserNameIdDto[] }>()(
  'GET_EMPLOYEES',
);
export const GET_EMPLOYEES_BY_ROLE = msgWithPayload<{
  payload: UserNameIdDto[];
}>()('GET_EMPLOYEES_BY_ROLE');
export const GET_EMPLOYEE_DATA = msgWithPayload<{
  payload: ProjectLastUsedDto;
}>()('GET_EMPLOYEE_DATA');
export const GET_LOCATIONS = msgWithPayload<{
  payload: CustomerLocationDto[];
}>()('GET_LOCATIONS');
//TODO: add types when moved to react-table v8
export const GET_MEMBER_ID = msgWithPayload<{ payload: any }>()(
  'GET_MEMBER_ID',
);
export const GET_PM = msgWithPayload<{ payload: UserNameIdDto[] }>()('GET_PM');
export const GET_PM_TEAM = msgWithPayload<{
  payload: ProjectManagerReportItem[];
}>()('GET_PM_TEAM');
export const GET_PROJECTS = msgWithPayload<{ payload: ProjectWebDto[] }>()(
  'GET_PROJECTS',
);
export const GET_PROJECTS_ALL = msgWithPayload<{
  payload: NonNullable<PageProjectWebDto['content']>;
}>()('GET_PROJECTS_ALL');

export const GET_PROJECTS_BY_CLIENT_ID = msgWithPayload<{
  payload: CustomerProjectsDto[];
}>()('GET_PROJECTS_BY_CLIENT_ID');
export const GET_PROJECTS_INFO = msgWithPayload<{ payload: TeamMemberDto }>()(
  'GET_PROJECTS_INFO',
);
export const GET_PROJECT_ID = msgWithPayload<{ payload: ProjectWebDto }>()(
  'GET_PROJECT_ID',
);
export const GET_REGISTERED_EMPLOYEE = msgWithPayload<{
  payload: UserFullDto;
}>()('GET_REGISTERED_EMPLOYEE');
export const GET_REPORT_CLIENT = msgWithPayload<{ payload: TotalReportDto }>()(
  'GET_REPORT_CLIENT',
);
export const GET_REPORT_PM = msgWithPayload<{
  payload: ProjectManagerReportItem[];
}>()('GET_REPORT_PM');

//new
export const GET_REPORT_PM_ALL = msgWithPayload<{
  payload: ProjectManagerReportItem[];
}>()('GET_REPORT_PM_ALL');
export const GET_REPORT_PM_EMPLOYEE = msgWithPayload<{
  payload: ProjectManagerEmployeeReport;
}>()('GET_REPORT_PM_EMPLOYEE');
export const GET_REPORT_PROJECT = msgWithPayload<{ payload: ProjectReport }>()(
  'GET_REPORT_PROJECT',
);
export const GET_REPORT_PROJECT_EMPLOYEE = msgWithPayload<{
  payload: ProjectEmployeeReport;
}>()('GET_REPORT_PROJECT_EMPLOYEE');
export const GET_REPORT_TOTAL = msgWithPayload<{ payload: TotalReportDto }>()(
  'GET_REPORT_TOTAL',
);
export const GET_REPORT_PRESALES_ALL = msgWithPayload<{
  payload: PresalesAllReportDTO;
}>()('GET_REPORT_PRESALES_ALL');
export const CLEAR_REPORT_PRESALES_ALL = msgWithPayload<{
  payload: null;
}>()('CLEAR_REPORT_PRESALES_ALL');
export const GET_SICKNESS_DATA = msgWithPayload<{ payload: SicknessesDto }>()(
  'GET_SICKNESS_DATA',
);
export const GET_TASKS = msgWithPayload<{ payload: EmployeeTask[] }>()(
  'GET_TASKS',
);
//TODO: update after moving to react-table v8
export const GET_TASK_ID = msgWithPayload<{ payload: any }>()('GET_TASK_ID');
export const GET_TEAM = msgWithPayload<{
  payload: PageEmployeeProjectTaskDto['content'];
}>()('GET_TEAM');
export const GET_TIME_TRACKED_DATA = msgWithPayload<{ payload: MonthLogs }>()(
  'GET_TIME_TRACKED_DATA',
);
export const GET_UNITS = msgWithPayload<{ payload: UnitDto[] }>()('GET_UNITS');
export const GET_VACATION_DATA = msgWithPayload<{ payload: VacationList }>()(
  'GET_VACATION_DATA',
);
//TODO: probably logic should be refactored, type after that
export const REMOVE_CLIENTS_EMAIL_FIELD = msgWithPayload<{ payload: any }>()(
  'REMOVE_CLIENTS_EMAIL_FIELD',
);
//TODO: probably logic should be refactored, type after that
export const REMOVE_CLIENTS_LOCATION_FIELD = msgWithPayload<{ payload: any }>()(
  'REMOVE_CLIENTS_LOCATION_FIELD',
);
export const RESET_CLIENTS_FIELD = msgWithPayload<{
  payload: CustomerRequestDto;
}>()('RESET_CLIENTS_FIELD');
export const UPDATE_CLIENT_PAGE = msgWithPayload<{ payload: boolean }>()(
  'UPDATE_CLIENT_PAGE',
);
export const SET_CLIENTS_DETAILS = msgWithPayload<{
  payload: {
    key: string;
    value: string;
    parentIndx: number;
    childIndx: number;
  };
}>()('SET_CLIENTS_DETAILS');
export const SET_CLIENTS_LOCATION_FIELD = msgWithPayload<{
  payload: {
    location: string | undefined;
    indx: number;
  };
}>()('SET_CLIENTS_LOCATION_FIELD');
export const UPDATE_PROJECT = msgWithPayload<{ payload: ProjectWebDto }>()(
  'UPDATE_PROJECT',
);
// *** ui
//TODO: to be updated, after all local state handling is moved into global space
export const CLEAR_TEXT = msgWithPayload<{ payload: any }>()('CLEAR_TEXT');
export const EDIT_MODE = msgWithPayload<{
  payload: {
    editValuesMode: boolean;
  };
}>()('EDIT_MODE');
export const ON_TOGGLE_VIEW = msgWithPayload<{
  payload: {
    [type: string]: boolean;
  };
}>()('ON_TOGGLE_VIEW');
export const SELECT_DATA = msgWithPayload<{ payload: any }>()('SELECT_DATA');
export const SET_CONFIRM_POP_UP = msgWithPayload<{
  payload: {
    status: boolean;
    rowId: any;
  };
}>()('SET_CONFIRM_POP_UP');
export const SET_CURRENT_PAGE = msgWithPayload<{ payload: any }>()(
  'SET_CURRENT_PAGE',
);
export const SET_DATE_VALUE = msgWithPayload<{ payload: any }>()(
  'SET_DATE_VALUE',
);
export const SET_DATE_VALUES_ARRAY = msgWithPayload<{ payload: any }>()(
  'SET_DATE_VALUES_ARRAY',
);
export const SET_EDIT_MODE = msgWithPayload<{
  payload: boolean;
}>()('SET_EDIT_MODE');
export const SET_END_DATE = msgWithPayload<{ payload: any }>()('SET_END_DATE');
export const SET_FILE = msgWithPayload<{ payload: any }>()('SET_FILE');
export const SET_FILE_NAME = msgWithPayload<{ payload: any }>()(
  'SET_FILE_NAME',
);
export const SET_ID = msgWithPayload<{ payload: any }>()('SET_ID');
export const SET_IS_POP_UP_APPROVED = msgWithPayload<{ payload: boolean }>()(
  'SET_IS_POP_UP_APPROVED',
);
export const SET_LOADER = msgWithPayload<{ payload: { isLoading: boolean } }>()(
  'SET_LOADER',
);
export const SET_NAVIGATE_URL = msgWithPayload<{ payload: string }>()(
  'SET_NAVIGATE_URL',
);
export const SET_NEXT_PAGE = msgWithPayload<{ payload: any }>()(
  'SET_NEXT_PAGE',
);
export const SET_PAGE_DATA = msgWithPayload<{ payload: any }>()(
  'SET_PAGE_DATA',
);
export const SET_PREVIOUS_PAGE = msgWithPayload<{ payload: any }>()(
  'SET_PREVIOUS_PAGE',
);
export const SET_SELECTABLES = msgWithPayload<{ payload: any }>()(
  'SET_SELECTABLES',
);
export const SET_SELECTED_PAGE = msgWithPayload<{ payload: any }>()(
  'SET_SELECTED_PAGE',
);
export const SET_START_DATE = msgWithPayload<{ payload: any }>()(
  'SET_START_DATE',
);
export const SET_TEXT = msgWithPayload<{ payload: any }>()('SET_TEXT');
export const SET_WARNING_POP_UP = msgWithPayload<{
  payload: boolean;
}>()('SET_WARNING_POP_UP');
export const SET_KEY = msgWithPayload<{ payload: number }>()('SET_KEY');
export const SET_BCOIN_IMAGES = msgWithPayload<{
  payload: HTMLImageElement[];
}>()('SET_BCOIN_IMAGES');
