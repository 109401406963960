import React from 'react';

interface EmployeeReportNameProps {
  employeeFullName?: {
    name?: string;
  };
  taskName?: string;
}

const EmployeeReportName: React.FC<EmployeeReportNameProps> = ({
  employeeFullName,
  taskName,
}) => {
  return (
    <div className='project_report_name'>
      {`${employeeFullName?.name} - ${taskName}`}
    </div>
  );
};

export default EmployeeReportName;
