import React, { ComponentPropsWithoutRef } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import LoginPage from '../pages/LoginPage';

type ProtectedComponentProps<
  TComponent extends
    | React.LazyExoticComponent<React.ComponentType<any>>
    | React.ComponentType<any>,
> = {
  component: TComponent;
  isPresale?: boolean | null;
} & ComponentPropsWithoutRef<TComponent>;

const ProtectedComponent = <
  TComponent extends
    | React.LazyExoticComponent<React.ComponentType<any>>
    | React.ComponentType<any>,
>({
  component: Component,
  isPresale,
  ...propsForComponent
}: ProtectedComponentProps<TComponent>) => {
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) return <LoginPage />;

  const ComponentContainer = withAuthenticationRequired(Component);

  return (
    <ComponentContainer
      {...(propsForComponent as ComponentPropsWithoutRef<TComponent>)}
      isPresale={isPresale}
    />
  );
};;;;

export default ProtectedComponent;
