export const textSuccess = {
  title: 'The request has been sent',
  subtitle: 'B-coin accrual is on the way!',
};

export const createTextState = (subtitle: string) => ({
  initialState: {
    title: 'Do you want to apply?',
    subtitle,
    cancel: 'No',
    confirm: 'Yes',
  },
  successState: textSuccess,
});

export const textWillComplete = createTextState(
  "If you're going to complete a task",
);
export const textCompleted = createTextState(
  'Have you completed the task and would like to receive b-coin?',
);
