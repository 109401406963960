import { ReactComponent as CancelIcon } from '../../../icons/CancelIcon.svg';
import Checkbox from '../../Checkbox/Checkbox';
import CalendarDatePicker from '../../DatePickers/DatePicker/CalendarDatePicker';
import { selectPMModalMain } from '../../Select/selectStyles';
import SimpleSelect from '../../Select/SimpleSelect';
import TextAreaField from '../../TextAreaField/TextAreaField';
import TextField from '../../TextField/TextField';
import Modal from '../Modal';
import styles from './../styles/project-modal.module.scss';
import dataPairStyles from '../../DataPair/data-pair.module.scss';
import {
  nameAndEmailInputLength,
  textareaLength,
} from '../../../constants/input-restrictions';
import DataPair from '../../DataPair/DataPair';
import { useCreateProjectModal } from './useCreateProjectModal';
import { CreateProjectModalData, CreateProjectModalProps } from './types';
import { useDateChange } from '../../../hooks/useDateChange';
import { createProjectInputChange } from '../../../utils/handleInputChange';
import CustomerSelect from '../../../modules/Projects/CustomerSelect/CustomerSelect';
import { useFieldNames } from '../../../hooks/useFieldNames';
import { useLocation } from 'react-router-dom';

const CreateProjectModal = ({
  customerLocationList,
  data,
  modalTitle,
  onClose,
  onSave,
  projectManagerList,
}: CreateProjectModalProps) => {
  const { pathname } = useLocation();
  const isPresale = pathname.includes('presale');
  const fieldNames = useFieldNames(isPresale);

  const {
    billableType,
    billableTypeCheckboxes,
    filteredCustomers,
    projectState,
    setProjectState,
    t: modalT,
    modalButtons,
    projectManagerOptions,
    handleProjectManagerChange,
    selectedProjectManager,
  } = useCreateProjectModal(
    customerLocationList,
    data,
    onSave,
    onClose,
    projectManagerList,
    pathname,
  );

  const {
    getMaxDate,
    getStartDate,
    handleStartDateChange,
    getEndDate,
    getMinDate,
    handleEndDateChange,
  } = useDateChange<CreateProjectModalData>({
    projectState,
    setProjectState,
  });

  return (
    <div className={styles.container}>
      <Modal
        buttons={modalButtons}
        closeRight
        onClose={onClose}
        onTopRight={onClose}
        styles={styles}
        title={modalTitle}
        topRightButton={<CancelIcon />}
      >
        <form className={styles.form}>
          <div className={styles.form_col}>
            <div className={styles.project_data}>
              <DataPair
                dataPairTitle={dataPairStyles.data_pair_title}
                title={`${fieldNames.name}*`}
              />
              <div className={styles.data_input}>
                <TextField
                  field='projectName'
                  value={projectState?.projectName || ''}
                  onChange={createProjectInputChange(
                    setProjectState,
                    'projectName',
                  )}
                  className={styles.project_name_input}
                  maxLength={nameAndEmailInputLength}
                />
              </div>
            </div>
            <div className={styles.project_data}>
              <DataPair
                dataPairTitle={dataPairStyles.data_pair_title}
                title={fieldNames.manager}
              />
              <div className={styles.data_input}>
                <SimpleSelect
                  options={projectManagerOptions}
                  onChange={handleProjectManagerChange}
                  value={selectedProjectManager}
                  styles={selectPMModalMain}
                />
              </div>
            </div>
            <div className={styles.project_data}>
              <DataPair
                dataPairTitle={dataPairStyles.data_pair_title}
                title={`${modalT('modals.fields.start_date')}`}
              />
              <div className='filled-input'>
                {/* startDate */}
                <CalendarDatePicker
                  onChange={handleStartDateChange}
                  inputClassName={styles.date_input}
                  maxDate={getMaxDate}
                  startDate={getStartDate}
                />
              </div>
            </div>
            <div className={styles.project_description}>
              <DataPair
                dataPairTitle={dataPairStyles.data_pair_title}
                title='Description'
              />
              <div className={styles.data_input}>
                <TextAreaField
                  field='description'
                  value={projectState?.description || ''}
                  onChange={createProjectInputChange(
                    setProjectState,
                    'description',
                  )}
                  fieldClassName={styles.description_input}
                  maxLength={textareaLength}
                />
              </div>
            </div>
          </div>
          <div className={styles.form_col}>
            {billableTypeCheckboxes.map((item) => (
              <div key={item.id} className={styles.project_data}>
                <DataPair
                  dataPairTitle={dataPairStyles.data_pair_title}
                  title={item.id}
                />
                <div className={styles.data_input}>
                  <Checkbox
                    checked={billableType === item.checked}
                    className={item.className}
                    disabled={item.disabled}
                    onChange={item.onChange}
                  />
                </div>
              </div>
            ))}
            <div className={styles.project_data}>
              <DataPair
                dataPairTitle={dataPairStyles.data_pair_title}
                title={`${modalT('modals.fields.end_date')}`}
              />
              <div className='filled-input'>
                {/* endDate */}
                <CalendarDatePicker
                  onChange={handleEndDateChange}
                  inputClassName={styles.date_input}
                  minDate={getMinDate}
                  startDate={getEndDate}
                />
              </div>
            </div>
            <CustomerSelect
              filteredCustomers={filteredCustomers}
              projectState={projectState}
              setProjectState={setProjectState}
              pathname={pathname}
            />
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default CreateProjectModal;
