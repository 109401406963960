export const itemInfoEN = {
  contact_name: 'Contact',
  description: 'Description',
  email: 'Email',
  location: 'Location',
  office: 'Office',
  phone: 'Phone',
  project_manager: 'Project manager',
  manager: 'Manager',
  start_date: 'Start date',
  unit: 'Unit',
} as const;
