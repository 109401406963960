import {
  CLEAR_REPORT_PM,
  CLEAR_REPORT_PM_EMPLOYEE,
  CLEAR_REPORT_PRESALES_ALL,
  CLEAR_REPORT_PRESALES_EMPLOYEE,
  CLEAR_REPORT_PROJECT,
  CLEAR_REPORT_PROJECT_EMPLOYEE,
  CLEAR_REPORT_TOTAL,
  GET_REPORT_PM,
  GET_REPORT_PM_ALL,
  GET_REPORT_PM_EMPLOYEE,
  GET_REPORT_PRESALES_ALL,
  GET_REPORT_PRESALES_EMPLOYEE,
  GET_REPORT_PROJECT,
  GET_REPORT_PROJECT_EMPLOYEE,
  GET_REPORT_TOTAL,
} from '../actions';
import {
  PresalesAllReportDTO,
  ProjectEmployeeReport,
  ProjectManagerEmployeeReport,
  ProjectManagerReportItem,
  ProjectReport,
  TotalReportDto,
} from '../../api/api-types';

export const getReportPMAction = (data: ProjectManagerReportItem[]) =>
  GET_REPORT_PM({
    payload: data,
  });

export const getReportPMAllAction = (data: ProjectManagerReportItem[]) =>
  GET_REPORT_PM_ALL({
    payload: data,
  });

export const getReportPMEmployeeAction = (data: ProjectManagerEmployeeReport) =>
  GET_REPORT_PM_EMPLOYEE({
    payload: data,
  });

export const clearReportPMAction = () => CLEAR_REPORT_PM({ payload: [] });

export const clearReportPMEmployeeAction = (
  data: ProjectManagerEmployeeReport,
) => CLEAR_REPORT_PM_EMPLOYEE({ payload: data });

export const getReportProjectAction = (reportProject: ProjectReport) =>
  GET_REPORT_PROJECT({ payload: reportProject });

export const clearReportProjectAction = (reportProject: ProjectReport) =>
  CLEAR_REPORT_PROJECT({ payload: reportProject });

export const getReportProjectEmployeeAction = (data: ProjectEmployeeReport) =>
  GET_REPORT_PROJECT_EMPLOYEE({ payload: data });

export const clearReportProjectEmployeeAction = () =>
  CLEAR_REPORT_PROJECT_EMPLOYEE({ payload: {} as ProjectEmployeeReport });

export const getReportTotalAction = (data: TotalReportDto) =>
  GET_REPORT_TOTAL({ payload: data });

export const clearReportTotalAction = (data: null) =>
  CLEAR_REPORT_TOTAL({ payload: data });

export const getReportPresalesAllAction = (data: PresalesAllReportDTO) => {
  return GET_REPORT_PRESALES_ALL({ payload: data });
};

export const clearReportPresalesAllAction = (data: null) => {
  return CLEAR_REPORT_PRESALES_ALL({ payload: data });
};

export const getReportPresalesEmployeeAction = (data: PresalesAllReportDTO) => {
  return GET_REPORT_PRESALES_EMPLOYEE({ payload: data });
};

export const clearReportPresalesEmployeeAction = (data: null) => {
  return CLEAR_REPORT_PRESALES_EMPLOYEE({ payload: data });
};
