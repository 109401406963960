import { useTranslation } from 'react-i18next';
import { CustomerSelectProps, FilteredCustomerReturn } from '../types';

export const useCustomerSelect = (
  props: CustomerSelectProps,
): FilteredCustomerReturn => {
  const { t } = useTranslation();
  const { filteredCustomers, projectState, setProjectState, pathname } = props;

  const isPresale = pathname.includes('presale');

  const customerChangeHandler = (e: { label: string; value: number }) =>
    setProjectState((prevState) => ({
      ...prevState,
      customerId: e.value,
      customerName: e.label,
    }));

  const customerValue = {
    label: projectState?.customerName,
    value: projectState?.customerId,
  };

  return {
    customerChangeHandler,
    customerValue,
    filteredCustomers,
    t,
    isPresale,
  };
};
