import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { groupBy } from '../../utils/filtering';
import { decimalToTime } from '../../utils/date-formatting';
import {
  CLEAR_REPORT_PRESALES_ALL,
  GET_REPORT_PRESALES_ALL,
} from '../../store/actions';
import {
  HoursByProjectAndEmployee,
  PresalesAllReportDTO,
} from '../../api/api-types';
import { RootState } from '../../store';
import {
  ClientProjectHours,
  PresalesAllTableDataItem,
  RowType,
  UseReportPresalesAllReturn,
} from './types';
import useGetPresalesAllReport from '../../hooks/useGetPresalesAllReport';
import { usePresalesAllTableData } from './hooks/usePresalesAllTableData';

const ROW_DEPTH = 1;

export const useReportPresalesAll = (): UseReportPresalesAllReturn => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const tableRef = useRef<HTMLTableElement>(null);

  const { endDate, startDate } = useParams<{
    endDate: string;
    startDate: string;
  }>();

  const { reportPresalesAll } = useAppSelector((state: RootState) => state);

  const dispatchGetReportPresalesAll = (data: PresalesAllReportDTO) =>
    dispatch(GET_REPORT_PRESALES_ALL({ payload: data }));

  const dispatchClearReportClient = () =>
    dispatch(CLEAR_REPORT_PRESALES_ALL({ payload: null }));

  const { isLoading } = useGetPresalesAllReport({
    getReportAction: dispatchGetReportPresalesAll,
    clearReportAction: dispatchClearReportClient,
  });

  const groupedByClient = groupBy<HoursByProjectAndEmployee>(
    reportPresalesAll.projectCountHoursDtoList ?? [],
    (item) => item.client ?? '',
  );

  const presalesAllRows: ClientProjectHours[] = Array.from(
    groupedByClient,
    ([client, projects]) => ({
      client,
      projects,
    }),
  );

  const presalesAllTableData = usePresalesAllTableData({
    presalesAllRows,
  });

  const [expandedRowId, setExpandedRowId] = useState<string | null>(
    localStorage.getItem('expandedClient') ?? null,
  );

  const goToEmployee = (row: RowType) => {
    if (row.depth !== 2) return;

    const employeePath = `../by-presales/presale${row.original.projectId}/employee${row.original.employeeId}/start${startDate}/end${endDate}/accrualBILLABLE,INTERNAL_BILLABLE,NON_BILLABLE`;

    navigate(employeePath);
  };

  const allSubRowsExpanded = (expand: boolean): Record<string, boolean> => {
    if (!expandedRowId) return {};

    return {
      [expandedRowId.split('.')[0]]: expand,
      [expandedRowId]: expand,
    };
  };

  const expandByDefault = allSubRowsExpanded(true);

  const totalClientHours = decimalToTime(
    presalesAllTableData
      .map((el: PresalesAllTableDataItem | null) => el?.totalHours ?? 0)
      .reduce((a, b) => a + b, 0),
  );

  return {
    ROW_DEPTH,
    presalesAllTableData,
    expandByDefault,
    goToEmployee,
    isLoading,
    setExpandedRowId,
    tableRef,
    totalClientHours,
  };
};
