import React from 'react';
import { ReactComponent as CancelIcon } from '../../../icons/CancelIcon.svg';
import Checkbox from '../../Checkbox/Checkbox';
import CalendarDatePicker from '../../DatePickers/DatePicker/CalendarDatePicker';
import { selectPMModalMain } from '../../Select/selectStyles';
import SimpleSelect from '../../Select/SimpleSelect';
import TextAreaField from '../../TextAreaField/TextAreaField';
import TextField from '../../TextField/TextField';
import Modal from '../Modal';
import styles from './../styles/project-modal.module.scss';
import dataPairStyles from '../../DataPair/data-pair.module.scss';
import {
  nameAndEmailInputLength,
  textareaLength,
} from '../../../constants/input-restrictions';
import DataPair from '../../DataPair/DataPair';
import EditCustomerSelect from '../../../modules/Projects/EditCustomerSelect/EditCustomerSelect';
import { withModal } from '../../../providers/ModalProvider';
import modals from '../../../constants/modals';
import { BaseModalComponentType, ModalNameToModalMap } from '../types';
import { useEditProjectModal } from './useEditProjectModal';
import { useModalButtons } from './hooks/useModalButtons';
import { editProjectInputChange } from '../../../utils/handleInputChange';
import { useProjectManagerOptions } from './hooks/useProjectManagerOptions';
import { ProjectData } from '../../../api/api-types';
import { useDateChange } from '../../../hooks/useDateChange';
import { useLocation } from 'react-router-dom';
import { useFieldNames } from '../../../hooks/useFieldNames';

const EditProjectModal = ({
  closeModal,
  payload,
}: BaseModalComponentType & {
  payload: ModalNameToModalMap['editProjectModal'];
}) => {
  const { pathname } = useLocation();
  const isPresale = pathname.includes('presale');

  const fieldNamesAndTitle = useFieldNames(isPresale);

  const {
    billableTypeCheckboxes,
    onSave,
    pmOptions,
    projectState,
    setProjectState,
    t,
  } = useEditProjectModal(payload);

  const buttons = useModalButtons(
    projectState,
    onSave,
    closeModal,
    t,
    isPresale,
  );

  const { selectedPMOption, handlePMChange } = useProjectManagerOptions(
    setProjectState,
    pmOptions,
    projectState,
  );

  const {
    getMaxDate,
    getStartDate,
    handleStartDateChange,
    getEndDate,
    getMinDate,
    handleEndDateChange,
  } = useDateChange<ProjectData>({
    projectState,
    setProjectState,
  });

  return (
    <div className={styles.container}>
      <Modal
        buttons={buttons}
        closeRight
        onClose={closeModal}
        onTopRight={closeModal}
        styles={styles}
        title={fieldNamesAndTitle.title}
        topRightButton={<CancelIcon />}
      >
        <form className={styles.form}>
          <div className={styles.form_col}>
            <div className={styles.project_data}>
              <DataPair
                dataPairTitle={dataPairStyles.data_pair_title}
                title={`${fieldNamesAndTitle.name}*`}
              />
              <div className={styles.data_input}>
                <TextField
                  field='projectName'
                  value={projectState?.projectName}
                  onChange={editProjectInputChange(
                    setProjectState,
                    'projectName',
                  )}
                  className={styles.project_name_input}
                  maxLength={nameAndEmailInputLength}
                />
              </div>
            </div>
            <div className={styles.project_data}>
              <DataPair
                dataPairTitle={dataPairStyles.data_pair_title}
                title={fieldNamesAndTitle.manager}
              />
              <div className={styles.data_input}>
                <SimpleSelect
                  options={pmOptions}
                  onChange={handlePMChange}
                  value={selectedPMOption}
                  styles={selectPMModalMain}
                />
              </div>
            </div>
            <div className={styles.project_data}>
              <DataPair
                dataPairTitle={dataPairStyles.data_pair_title}
                title={`${t('modals.fields.start_date')}`}
              />
              <div className='filled-input'>
                <CalendarDatePicker
                  onChange={handleStartDateChange}
                  inputClassName={styles.date_input}
                  maxDate={getMaxDate}
                  startDate={getStartDate}
                />
              </div>
            </div>
            <div className={styles.project_description}>
              <DataPair
                dataPairTitle={dataPairStyles.data_pair_title}
                title='Description'
              />
              <div className={styles.data_input}>
                <TextAreaField
                  field='description'
                  value={projectState?.description ?? ''}
                  onChange={editProjectInputChange(
                    setProjectState,
                    'description',
                  )}
                  fieldClassName={styles.description_input}
                  maxLength={textareaLength}
                />
              </div>
            </div>
          </div>
          <div className={styles.form_col}>
            {billableTypeCheckboxes.map((item) => (
              <div key={item.id} className={styles.project_data}>
                <DataPair
                  dataPairTitle={dataPairStyles.data_pair_title}
                  title={item.id}
                />
                <div className={styles.data_input}>
                  <Checkbox
                    checked={projectState.billableType === item.checked}
                    className={item.className}
                    disabled={item.disabled}
                    onChange={item.onChange}
                  />
                </div>
              </div>
            ))}
            <div className={styles.project_data}>
              <DataPair
                dataPairTitle={dataPairStyles.data_pair_title}
                title={`${t('modals.fields.end_date')}`}
              />
              <div className='filled-input'>
                <CalendarDatePicker
                  onChange={handleEndDateChange}
                  inputClassName={styles.date_input}
                  minDate={getMinDate}
                  startDate={getEndDate}
                />
              </div>
            </div>
            <EditCustomerSelect
              projectState={projectState}
              setProjectState={setProjectState}
              isPresale={isPresale}
            />
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default withModal(modals.editProjectModal)(EditProjectModal);
