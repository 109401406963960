import { useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { reducer, valuesInitialState } from '../reducer/reducer';
import { getAllPresalesEmployeeReport } from '../api/reports';
import { TotalReportDto } from '../api/api-types';
import { useAppSelector } from './redux';

type UseGetPresalesEmployeeReportProps = {
  getReportAction: (data: TotalReportDto) => void;
  clearReportAction: () => void;
};

const useGetPresalesEmployeeReport = (
  props: UseGetPresalesEmployeeReportProps,
) => {
  const { t } = useTranslation();
  const { employeeId, endDate, billable, startDate } = useParams();
  const parsedEmployeeId = employeeId ? parseInt(employeeId, 10) : undefined;
  const { key } = useAppSelector((state) => state);

  const [handleValues, setHandleValues] = useReducer(
    reducer,
    valuesInitialState(t),
  );

  const { isLoading } = handleValues;

  const getReport = async () => {
    if (!endDate || !startDate) return;
    return getAllPresalesEmployeeReport({
      employeeId: parsedEmployeeId,
      endDate,
      startDate,
    });
  };

  useEffect(() => {
    setHandleValues({ type: 'SET_LOADER', payload: { isLoading: true } });
    getReport()
      .then((data) => {
        setHandleValues({
          type: 'SET_LOADER',
          payload: { isLoading: false },
        });
        if (!data) return;
        props.getReportAction(data);
      })
      .finally(() => {
        setHandleValues({
          type: 'SET_LOADER',
          payload: { isLoading: false },
        });
      });
  }, [employeeId, endDate, billable, startDate, key]);

  return {
    handleValues,
    isLoading,
  };
};

export default useGetPresalesEmployeeReport;
