import { useTranslation } from 'react-i18next';

export const useFieldNames = (isPresale: boolean) => {
  const { t } = useTranslation();

  const getFieldNamesAndTitle = () => {
    if (isPresale)
      return {
        name: t('modals.fields.presale_name'),
        manager: t('modals.fields.manager'),
        title: t('modals.titles.presale'),
      };

    return {
      name: t('modals.fields.project_name'),
      manager: t('modals.fields.project_manager'),
      title: t('modals.titles.project'),
    };
  };
  const fieldNamesAndTitle = getFieldNamesAndTitle();

  return fieldNamesAndTitle;
};
