import { toast } from 'react-toastify';
import { useState } from 'react';
import Button from '../../components/Button/Button';
import { postVacationData } from '../../api/vacation';
import {
  createdVacationHalfDayRequest,
  createdVacationRangeRequest,
  createdVacationRequest,
} from '../../utils/request-objects';
import { postTimeLog } from '../../api/tracking';
import { useTranslation } from 'react-i18next';
import { Msg } from '../../store/actions';
import { ProjectDto } from '../../api/api-types';

type ButtonBlockProps = {
  checkboxState: number;
  currentDate: string;
  currentSelectedMonth: React.MutableRefObject<string | undefined>;
  dayAlreadyLogged?: boolean;
  dayOffType: string;
  employeeData: ProjectDto[];
  endDate: string;
  handleValues: any;
  setHandleValues: React.Dispatch<Msg>;
  startDate: string;
  updateVacationList: (value: string | undefined) => void;
};

const ButtonBlock = (props: ButtonBlockProps) => {
  const {
    checkboxState,
    currentDate,
    currentSelectedMonth,
    dayAlreadyLogged,
    dayOffType,
    employeeData,
    endDate,
    handleValues,
    setHandleValues,
    startDate,
    updateVacationList,
  } = props;

  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const clearedVacationRequest = {
    startDate: '',
    endDate: '',
  };

  const handleSuccess = () => {
    updateVacationList(currentSelectedMonth.current);
    setHandleValues({
      type: 'CLEAR_TEXT',
      payload: clearedVacationRequest,
    });
    setIsSubmitting(false);
  };

  const handleError = (error: any) => {
    toast.error(error.response?.data?.message || 'An error occurred');
    setIsSubmitting(false);
  };

  const handleHalfDayVacation = async () => {
    if (isSubmitting) return;
    if (dayAlreadyLogged) {
      toast.warn(t('warning.day_already_logged'));
      return;
    }

    try {
      setIsSubmitting(true);
      await postVacationData({
        data: createdVacationHalfDayRequest(handleValues, employeeData),
      });
      handleSuccess();
    } catch (error) {
      handleError(error);
    }
  };

  const handleRangeVacation = async () => {
    if (isSubmitting) return;

    try {
      setIsSubmitting(true);
      await postVacationData({
        data: createdVacationRangeRequest(
          handleValues,
          checkboxState,
          employeeData,
        ),
      });
      handleSuccess();
    } catch (error) {
      handleError(error);
    }
  };

  const handleDefaultVacation = async () => {
    if (isSubmitting) return;
    if (dayAlreadyLogged) {
      toast.warn(t('warning.day_already_logged'));
      return;
    }

    try {
      setIsSubmitting(true);
      await postTimeLog(createdVacationRequest(handleValues, employeeData));
      handleSuccess();
    } catch (error) {
      handleError(error);
    }
  };

  const chooseBtnType = () => {
    switch (true) {
      case dayOffType === t('vacation.vacation_data.paid_vacations') &&
        checkboxState === 1:
      case dayOffType === t('vacation.vacation_data.unpaid_day_offs') &&
        checkboxState === 1:
        return (
          <Button
            label={t('buttons.book') as string}
            styleClass='btn__rounded-blue'
            disabled={dayOffType === '' || currentDate === '' || isSubmitting}
            onClick={handleHalfDayVacation}
          />
        );

      case dayOffType === t('vacation.vacation_data.paid_vacations') &&
        checkboxState === 0:
      case dayOffType === t('vacation.vacation_data.unpaid_day_offs') &&
        checkboxState === 0:
        return (
          <Button
            label={t('buttons.book') as string}
            styleClass='btn__rounded-blue'
            disabled={!dayOffType || !startDate || !endDate || isSubmitting}
            onClick={handleRangeVacation}
          />
        );

      default:
        return (
          <Button
            label={t('buttons.book') as string}
            styleClass='btn__rounded-blue'
            disabled={dayOffType === '' || currentDate === '' || isSubmitting}
            onClick={handleDefaultVacation}
          />
        );
    }
  };

  return chooseBtnType();
};

export default ButtonBlock;
