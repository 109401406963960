import { ProjectData } from '../../../../api/api-types';
import { TranslationFunction } from '../../../type-helpers/translation';

interface ModalButton {
  id: string;
  label: string;
  className: string;
  save?: () => void;
  close?: () => void;
  type?: 'button' | 'reset' | 'submit';
  disabled?: boolean;
}

export const useModalButtons = (
  projectState: ProjectData,
  onSave: (projectState: ProjectData) => void,
  closeModal: () => void,
  t: TranslationFunction,
  isPresale: boolean,
): ModalButton[] => {
  return [
    {
      id: '1',
      label: t('buttons.save'),
      className: 'btn__rounded-blue',
      save: () => onSave(projectState),
      type: 'submit',
      disabled:
        !projectState.projectName || (!isPresale && !projectState.customerId),
    },
    {
      id: '2',
      label: t('buttons.cancel'),
      className: 'btn__string--normal--grey',
      close: closeModal,
    },
  ];
};
