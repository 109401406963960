import { useTranslation } from 'react-i18next';
import useBillable, { WithBillableType } from '../../../hooks/useBillable';
import { useAppSelector } from '../../../hooks/redux';
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { ModalNameToModalMap } from '../types';
import { TeamMemberState, UseTeamMemberModalReturn } from './types';
import { EmployeeProjectTaskDto } from '../../../api/api-types';
import { getOnlyNumbers } from '../../../utils/number-handling';
import { useLocation } from 'react-router-dom';

export const useTeamMemberModal = (
  payload: ModalNameToModalMap['addEditProjectTeamMemberModal'],
): UseTeamMemberModalReturn => {
  const { t } = useTranslation();
  const { modalTitle, onSave, data } = payload;
  const { pathname } = useLocation();
  const isPresale = pathname.includes('presale');

  const { employeesByRole, projectData, taskList } = useAppSelector(
    (state) => state,
  );

  const initializeTeamMemberState = (
    data: EmployeeProjectTaskDto | null = null,
  ) => {
    const {
      billableType = isPresale ? 'INTERNAL_BILLABLE' : '',
      employeeId = null,
      endDate = null,
      id = null,
      role = null,
      startDate = '',
      task = { id: null },
      overtime = null,
    } = data ?? {};

    return {
      billableType,
      employeeId,
      endDate,
      id,
      role,
      startDate,
      taskId: task?.id ?? null,
      overtime,
    };
  };

  const [validationMessage, setValidationMessage] = useState('');
  const [teamMemberState, setTeamMemberState] = useState<TeamMemberState>(
    initializeTeamMemberState(data as EmployeeProjectTaskDto),
  );

  const isRequiredStartDate =
    (Boolean(teamMemberState.overtime?.overtimeRate) ||
      Boolean(teamMemberState.overtime?.fte)) &&
    !teamMemberState.overtime?.startDate; // start date is required if overtime rate is set

  const employeeOptions = employeesByRole?.map((employee) => ({
    label: employee.name,
    value: employee.id,
  }));

  const billableTypeCheckboxes = useBillable({
    className: 'checkbox_reversed',
    disabled: false,
    setState: setTeamMemberState as Dispatch<SetStateAction<WithBillableType>>,
  });

  const taskOptions = taskList?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const overtimeRateChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const newOvertimeRate = Number(getOnlyNumbers(e.target.value));
    const fteUnset =
      teamMemberState.overtime?.fte === null ||
      teamMemberState.overtime?.fte === 0;

    setTeamMemberState((state) => {
      const updatedState = {
        ...state,
        overtime: {
          ...state.overtime,
          overtimeRate: newOvertimeRate,
        },
      };

      if (newOvertimeRate === 0 && fteUnset) {
        updatedState.overtime.startDate = '';
        updatedState.overtime.endDate = '';
      }

      return updatedState;
    });
  };

  const FTEChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const fteValue = inputValue === '' ? null : Number(inputValue);

    const validValues = [0, 0.5, 1, null];

    if (!validValues.includes(fteValue)) {
      setValidationMessage('FTE must be 0, 0.5, 1 or empty.');
      return;
    } else {
      setValidationMessage('');
    }

    setTeamMemberState((state) => ({
      ...state,
      overtime: {
        ...state.overtime,
        fte: fteValue,
        overtimeRate: state.overtime?.overtimeRate,
      },
    }));
  };

  return {
    FTEChangeHandler,
    billableTypeCheckboxes,
    employeeOptions,
    isRequired: isRequiredStartDate,
    modalTitle,
    onSave,
    overtimeRateChangeHandler,
    projectData,
    setTeamMemberState,
    t,
    taskOptions,
    teamMemberState,
    validationMessage,
  };
};
