import { useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { reducer, valuesInitialState } from '../reducer/reducer';
import { getPresaleAllReport } from '../api/reports';
import { PresalesAllReportDTO } from '../api/api-types';
import { useAppSelector } from './redux';

type UseGetTotalReportProps = {
  getReportAction: (data: PresalesAllReportDTO) => void;
  clearReportAction: () => void;
};

const useGetPresalesAllReport = (props: UseGetTotalReportProps) => {
  const { t } = useTranslation();
  const { endDate, startDate } = useParams();
  const { key } = useAppSelector((state) => state);

  const [handleValues, setHandleValues] = useReducer(
    reducer,
    valuesInitialState(t),
  );

  const { isLoading } = handleValues;

  const getReport = async () => {
    if (!endDate || !startDate) return;

    return getPresaleAllReport({
      endDate,
      startDate,
    });
  };

  useEffect(() => {
    setHandleValues({ type: 'SET_LOADER', payload: { isLoading: true } });
    getReport()
      .then((data) => {
        setHandleValues({
          type: 'SET_LOADER',
          payload: { isLoading: false },
        });
        if (!data) return;
        props.getReportAction(data);
      })
      .finally(() => {
        setHandleValues({
          type: 'SET_LOADER',
          payload: { isLoading: false },
        });
      });
  }, [endDate, startDate, key]);

  return {
    handleValues,
    isLoading,
  };
};

export default useGetPresalesAllReport;
