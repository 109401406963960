import SimpleSelect from '../../../components/Select/SimpleSelect';
import { controlsListingStyle } from '../../../components/Select/selectStyles';
import { PMFilterProps } from './types';
import { usePMFilter } from './usePMFilter';

const PMFilter = ({
  clearSelectedProject,
  pmList,
  registeredEmployee,
  selectedPMId,
  selectedPMName,
  setCurrentPage,
  setHandleValues,
  t,
  isPresale,
}: PMFilterProps) => {
  const { pmOptions, PMValue, PMOnChangeHandler } = usePMFilter({
    clearSelectedProject,
    pmList,
    registeredEmployee,
    selectedPMId,
    selectedPMName,
    setCurrentPage,
    setHandleValues,
    t,
  });

  const labelName = isPresale
    ? 'presales.listing.title'
    : 'projects.listing.title';

  return (
    <SimpleSelect
      selectWrapperClass='select_horizontal_wrapper'
      selectTitleClassName='select_title'
      selectTitle={t(labelName) as string}
      options={pmOptions}
      value={PMValue}
      onChange={PMOnChangeHandler}
      styles={controlsListingStyle}
    />
  );
};

export default PMFilter;
