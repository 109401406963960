import { HoursByProjectAndEmployee } from '../../../api/api-types';
import { decimalToTime, timeToDecimal } from '../../../utils/date-formatting';
import { sortByDefault } from '../../../utils/filtering';

export const createProjectSubRows = (
  filteredProjects: HoursByProjectAndEmployee[],
  groupByProjectRows: {
    project: string;
    employees: HoursByProjectAndEmployee[];
  }[],
) => {
  return filteredProjects
    .map((project) => {
      const projectTotalHours = groupByProjectRows
        .find((item) => item.project === `${project?.projectNameAndId?.id}`)
        ?.employees.map((element: HoursByProjectAndEmployee) =>
          timeToDecimal(element.countHours ?? ''),
        )
        .reduce((a, b) => a + b, 0);

      return {
        billableHours: project.billableHours,
        name: project.projectNameAndId?.name,
        notBillableHours: project.notBillableHours,
        projectId: project.projectNameAndId?.id,
        countHours: decimalToTime(projectTotalHours ?? 0),
        subRows: groupByProjectRows
          .find((item) => item?.project === `${project?.projectNameAndId?.id}`)
          ?.employees.map((el) => ({
            billableHours: el.billableHours,
            employeeId: el.employeeFullName?.id,
            name: el.employeeFullName?.name,
            notBillableHours: el.notBillableHours,
            projectId: project.projectNameAndId?.id,
            task: el.taskNameAndId?.name,
          }))
          .sort((a, b) => sortByDefault(a, b, 'name')),
      };
    })
    .sort((a, b) => sortByDefault(a, b, 'name'));
};
