/* eslint-disable @typescript-eslint/no-explicit-any */
import { NavigateFunction } from 'react-router-dom';
import { restAccrualTypes } from '../constants/select-values';
import { ACCRUAL_FILTER_TYPE } from '../translations/accrualTypeFilterEN';
import { TranslationFunction } from '../components/type-helpers/translation';
import { useDispatch } from 'react-redux';
import { AccrualTypeArray } from '../pages/ReportsPage.tsx/types';

type GetReports = {
  accrualTypeFilter: AccrualTypeArray;
  clientId?: number;
  departmentName: string;
  employeeId?: number;
  employeeTaskName?: string;
  end: string;
  navigate: NavigateFunction;
  projectId: string;
  start: string;
  t: TranslationFunction;
  typeOfReport: string;
  userId?: string;
};

export const getReports = ({
  accrualTypeFilter,
  clientId,
  departmentName,
  employeeId,
  employeeTaskName,
  end,
  navigate,
  projectId,
  start,
  t,
  typeOfReport,
  userId,
}: GetReports) => {
  let accrual = '';
  const typesEmpty = accrualTypeFilter.every(
    (type) => type.value === ACCRUAL_FILTER_TYPE.ALL,
  );
  const allSelected =
    !typesEmpty &&
    accrualTypeFilter.some((type) => type.value === ACCRUAL_FILTER_TYPE.ALL);
  if (typesEmpty || allSelected) {
    accrual = restAccrualTypes(t)
      .map((type) => type.value)
      .join(',');
  } else {
    accrual = accrualTypeFilter.map((el) => el.value).join(',');
  }

  const dispatch = useDispatch();

  // TODO: make proper routing

  const getByAllPresales = () => {
    dispatch({ type: 'SET_KEY' });
    navigate(`by-presales/start${start}/end${end}`);
  };

  const getByPresale = () => {
    dispatch({ type: 'SET_KEY' });
    navigate(
      `by-presales/presale${projectId}/start${start}/end${end}/accrual${accrual}`,
    );
  };

  const getByEmployeeAllPresales = () => {
    dispatch({ type: 'SET_KEY' });
    navigate(
      `by-presales/employee${employeeId}/start${start}/end${end}/accrual${accrual}`,
    );
  };

  const getByPresaleEmployee = () => {
    dispatch({ type: 'SET_KEY' });
    navigate(
      `by-presales/presale${projectId}/employee${employeeId}/start${start}/end${end}/accrual${accrual}`,
    );
  };

  const getByProjects = () => {
    dispatch({ type: 'SET_KEY' });
    navigate(
      `by-projects/project${projectId}/start${start}/end${end}/accrual${accrual}`,
    );
  };

  const getByProjectsTasks = () => {
    dispatch({ type: 'SET_KEY' });
    navigate(
      `by-projects/project${projectId}/start${start}/end${end}/accrual${accrual}/tasks${employeeTaskName?.replace(
        /\//g,
        '%2F',
      )}`,
    );
  };

  const getByEmployees = () => {
    dispatch({ type: 'SET_KEY' });
    navigate(
      `by-projects/project${projectId}/employee${employeeId}/start${start}/end${end}/accrual${accrual}`,
    );
  };

  const getByEmployeesTasks = () => {
    dispatch({ type: 'SET_KEY' });
    navigate(
      `by-projects/project${projectId}/employee${employeeId}/start${start}/end${end}/accrual${accrual}/tasks${employeeTaskName?.replace(
        /\//g,
        '%2F',
      )}`,
    );
  };

  const getAll = () => {
    dispatch({ type: 'SET_KEY' });
    return navigate(`by-pm/start${start}/end${end}/accrual${accrual}`);
  };

  const getByPM = () => {
    dispatch({ type: 'SET_KEY' });
    return navigate(
      `by-pm/pm${userId}/start${start}/end${end}/accrual${accrual}`,
    );
  };

  const getByPMEmployees = () => {
    dispatch({ type: 'SET_KEY' });
    navigate(
      `by-pm/pm${userId}/employee${employeeId}/start${start}/end${end}/accrual${accrual}`,
    );
  };

  const getByClient = () => {
    dispatch({ type: 'SET_KEY' });
    localStorage.removeItem('expandedClient');
    navigate(
      `by-client${clientId ? `/client${clientId}` : ''}${
        employeeId ? `/employee${employeeId}` : ''
      }/start${start}/accrual${accrual}/end${end}`,
    );
  };

  const getByTotal = () => {
    dispatch({ type: 'SET_KEY' });
    localStorage.removeItem('expandedEmployee');
    navigate(
      `by-total${departmentName ? `/department${departmentName}` : ''}${
        employeeId ? `/employee${employeeId}` : ''
      }/start${start}/accrual${accrual}/end${end}`,
    );
  };

  const getReportsByCase = () => {
    switch (true) {
      case !!(
        typeOfReport === t('reports.types.value.by_project') &&
        !employeeId &&
        !employeeTaskName
      ):
        return getByProjects();

      case !!(
        typeOfReport === t('reports.types.value.by_project') &&
        !employeeId &&
        employeeTaskName
      ):
        return getByProjectsTasks();

      case !!(
        typeOfReport === t('reports.types.value.by_project') &&
        employeeId &&
        !employeeTaskName
      ):
        return getByEmployees();

      case !!(
        typeOfReport === t('reports.types.value.by_project') &&
        employeeId &&
        employeeTaskName
      ):
        return getByEmployeesTasks();

      case !!(
        typeOfReport === t('reports.types.value.by_pm') &&
        !employeeId &&
        userId === ''
      ):
        return getAll();

      case !!(typeOfReport === t('reports.types.value.by_pm') && !employeeId):
        return getByPM();

      case !!(typeOfReport === t('reports.types.value.by_pm') && employeeId):
        return getByPMEmployees();

      case typeOfReport === t('reports.types.value.by_client'):
        return getByClient();

      case typeOfReport === t('reports.types.value.by_total'):
        return getByTotal();

      case typeOfReport === t('reports.types.value.by_presale') &&
        !projectId &&
        !employeeId:
        return getByAllPresales();

      case typeOfReport === t('reports.types.value.by_presale') &&
        !!projectId &&
        !employeeId:
        return getByPresale();

      case typeOfReport === t('reports.types.value.by_presale') &&
        !!projectId &&
        !!employeeId:
        return getByPresaleEmployee();

      case typeOfReport === t('reports.types.value.by_presale') && !!employeeId:
        return getByEmployeeAllPresales();

      default:
        return null;
    }
  };
  return getReportsByCase;
};
