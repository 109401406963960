import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { formatDateYYYYMMDD } from '../utils/date-formatting';

interface HasStartDate {
  startDate?: string;
  endDate?: string;
}

type UseDateChangeReturnType = {
  getEndDate: Date | null;
  getMaxDate: Date;
  getMinDate: Date | null;
  getStartDate: Date | null;
  handleEndDateChange: (selectedDate: Date | null) => void;
  handleStartDateChange: (selectedDate: Date | null) => void;
};

export const useDateChange = <T extends HasStartDate>({
  projectState,
  setProjectState,
}: {
  projectState: T;
  setProjectState: Dispatch<SetStateAction<T>>;
}): UseDateChangeReturnType => {
  // start date change handler
  const handleStartDateChange = useCallback(
    (selectedDate: Date | null) => {
      setProjectState((currentState) => ({
        ...currentState,
        startDate: selectedDate
          ? formatDateYYYYMMDD(selectedDate)
          : currentState.startDate,
      }));
    },
    [setProjectState],
  );

  const getMaxDate = useMemo(
    () => (projectState.endDate ? new Date(projectState.endDate) : new Date()),
    [projectState.endDate],
  );

  const getStartDate = useMemo(() => {
    if (!projectState?.startDate) return null;

    const startDate = new Date(projectState.startDate);

    return new Date(startDate.setMinutes(startDate.getTimezoneOffset()));
  }, [projectState.startDate]);

  // end date change handler
  const handleEndDateChange = useCallback(
    (selectedDate: Date | null) => {
      setProjectState((currentState) => ({
        ...currentState,
        endDate: selectedDate
          ? formatDateYYYYMMDD(selectedDate)
          : currentState.endDate,
      }));
    },
    [setProjectState],
  );

  const getMinDate = useMemo(
    () => (projectState.startDate ? new Date(projectState.startDate) : null),
    [projectState.startDate],
  );

  const getEndDate = useMemo(
    () => (projectState.endDate ? new Date(projectState.endDate) : null),
    [projectState.endDate],
  );

  return {
    getEndDate,
    getMaxDate,
    getMinDate,
    getStartDate,
    handleEndDateChange,
    handleStartDateChange,
  };
};
