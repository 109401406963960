import { toast } from 'react-toastify';
import { postTimeLog } from '../../../../api/tracking';
import { format } from 'date-fns';
import { UseOnSubmitHandlerProps } from '../types';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

export const useOnSubmitHandler = ({
  formValues,
  closeModal,
}: UseOnSubmitHandlerProps) => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!formValues.accrualType || !formValues.taskId) return;

    if (isSubmitting) return;

    setIsSubmitting(true);

    const data = {
      date: format(new Date(formValues.date), 'dd.MM.yyyy'),
      duration: {
        hours: Number(formValues.hours),
        minutes: Number(formValues.minutes),
      },
      eptId: formValues.taskId,
      billableType: formValues.accrualType,
      note: formValues.comment,
    };

    try {
      await postTimeLog(data);

      dispatch({ type: 'SET_KEY' }); // for trigering re-render of the page after time log was added

      toast.success('Time was logged successfully');
      closeModal();
    } catch (error) {
      toast.error('Failed to log time');
    } finally {
      setIsSubmitting(false);
    }
  };

  return { onSubmit, isSubmitting };
};
