import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import Button from '../../../components/Button/Button';
import { useAppDispatch } from '../../../hooks/redux';
import { ReactComponent as CancelIcon } from '../../../icons/CancelIcon.svg';
import { Tooltip } from 'react-tooltip';
import commonStyles from '../../../styles/pm-flow.module.scss';
import {
  EmployeeProjectTaskDto,
  EmployeeTask,
  PageEmployeeProjectTaskDto,
} from '../../../api/api-types';

interface Row {
  id: string;
  original: {
    employeeId: number;
    employeeName: string;
    id: number;
    startDate: string;
    task: EmployeeTask;
    overtime?: {
      overtimeRate?: number | null;
      fte?: number | null;
    } | null;
  };
}

type UseTableSetupProps = {
  memberList: PageEmployeeProjectTaskDto['content'];
  onDeleteMember: (teamMemberId: number) => Promise<void>;
  openEditTeamMemberModal: (data: EmployeeProjectTaskDto) => void;
  statusCurrent: React.MutableRefObject<boolean>;
  toggleViewValue: boolean;
};

export const useTableSetup = ({
  memberList,
  onDeleteMember,
  openEditTeamMemberModal,
  statusCurrent,
  toggleViewValue,
}: UseTableSetupProps) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const nameBtnCell = ({ row }: { row: Row }) =>
    !!row.original.employeeName && (
      <Button
        label={`${row.original.employeeName}`}
        styleClass='btn__string--black--grey'
        onClick={() => {
          dispatch({ type: 'GET_MEMBER_ID', payload: row.original });
          openEditTeamMemberModal(row.original);
        }}
      />
    );

  const cancelBtnCell = ({ row }: { row: Row }) => (
    <>
      <Button
        id={row.id.toString()}
        icon={statusCurrent.current && <CancelIcon />}
        styleClass={
          statusCurrent.current ? 'btn__string--underlined--grey' : ''
        }
        onClick={() => onDeleteMember(row.original.id)}
      />

      <Tooltip
        id={row.id.toString()}
        className={commonStyles.toolTip}
        place='top'
        delayShow={500}
      >
        <div className={commonStyles.toolTipTxt}>
          {t('warning.employee_deactivated')}
        </div>
      </Tooltip>
    </>
  );

   const overtimeRateCell = ({ row }: { row: Row }) => {
     const value = row.original.overtime?.overtimeRate;
     return value === null || value === undefined ? '-' : value;
   };

   const fteCell = ({ row }: { row: Row }) => {
     const value = row.original.overtime?.fte;
     return value === null || value === undefined ? '-' : value;
   };

  const teamDetailedColumns = useMemo(
    () => [
      {
        Header: t('columns.employee_name'),
        accessor: 'employeeName',
        Cell: nameBtnCell,
      },
      {
        Header: t('columns.overtime_rate'),
        accessor: 'overtime.overtimeRate',
        Cell: overtimeRateCell,
      },
      {
        Header: t('columns.fte'),
        accessor: 'overtime.fte',
        Cell: fteCell,
      },
      {
        Header: t('columns.start_date'),
        accessor: 'startDate',
      },
      {
        Header: t('columns.end_date'),
        accessor: 'endDate',
      },
      {
        Header: t('columns.task'),
        accessor: 'task.name',
      },

      {
        Header: '',
        accessor: 'removeButton',
        Cell: cancelBtnCell,
      },
    ],
    [],
  );

  const teamColumns = useMemo(
    () => [
      {
        Header: t('columns.employee_name'),
        accessor: 'employeeName',
        Cell: nameBtnCell,
      },
      {
        Header: t('columns.role'),
        accessor: 'role',
      },
      {
        Header: t('columns.task'),
        accessor: 'task.name',
      },
      {
        Header: '',
        accessor: 'removeButton',
        Cell: cancelBtnCell,
      },
    ],
    [],
  );

  const tableInstance = useTable(
    // add proper types for the tableInstance
    {
      //@ts-ignore
      columns: toggleViewValue ? teamDetailedColumns : teamColumns,
      data: memberList ?? [],
      //@ts-ignore
      initialState: { pageIndex: 0 },
      manualPagination: true,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
  );
  // add types for return values
  return {
    teamDetailedColumns,
    teamColumns,
    tableInstance,
  };
};
