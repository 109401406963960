import { useTranslation } from 'react-i18next';
import useBillable from '../../../hooks/useBillable';
import { useAppSelector } from '../../../hooks/redux';
import { ProjectData } from '../../../api/api-types';
import { useState } from 'react';
import { EditProjectProps } from '../types';
import { UseEditProjectModalReturn } from './types';

export const useEditProjectModal = (
  payload: EditProjectProps,
): UseEditProjectModalReturn => {
  const { t } = useTranslation();
  const { onSave } = payload;

  const {
    pmList: projectManagerList,
    projectData: {
      billableType,
      customerId,
      description,
      endDate,
      managerIdAndName,
      projectName,
      startDate,
      isPresale,
    },
  } = useAppSelector((state) => state);

  const [projectState, setProjectState] = useState<ProjectData>({
    billableType: billableType ?? 'INTERNAL_BILLABLE',
    customerId: customerId,
    description: description ?? '',
    endDate: endDate,
    projectManagerId: managerIdAndName?.id ?? null,
    projectName: projectName ?? '',
    startDate,
    isPresale: isPresale ?? false,
  });

  const billableTypeCheckboxes = useBillable({
    className: 'modal_default_checkbox',
    disabled: false,
    setState: setProjectState,
  });

  const pmOptions = projectManagerList.map((pm) => ({
    label: pm.name ?? '',
    value: pm.id ?? null,
  }));

  return {
    billableTypeCheckboxes,
    pmOptions,
    projectState,
    setProjectState,
    onSave,
    t,
  };
};
