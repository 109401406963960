import React from 'react';

import styles from './../styles/report-modal.module.scss';
import dataPairStyles from '../../DataPair/data-pair.module.scss';

import { textareaLength } from '../../../constants/input-restrictions';
import modals from '../../../constants/modals';

import Modal from '../Modal';
import { ReactComponent as PenIcon } from '../../../icons/PenIcon.svg';
import BillableTypeCheckboxes from './components/BillableTypeCheckboxes/BillableTypeCheckboxes';
import ReportDescription from './components/ReportDescription/ReportDescription';
import { ReportData } from './components/ReportData/ReportData';

import { useEditProjectReportModal } from './useEditProjectReportModal';
import { withModal } from '../../../providers/ModalProvider';

import {
  selectPMModalMain,
  selectProjectStatus,
} from '../../Select/selectStyles';

import { BaseModalComponentType, ModalNameToModalMap } from '../types';
import { SelectOption, ChangeHandler, CustomStyles } from './components/ReportData/types';

import { durationSelectHandler } from './helpers/durationSelectHandler';
import { projectSelectHandler } from './helpers/projectSelectHandler';
import { taskSelectHandler } from './helpers/taskSelectHandler';
import { descriptionChangeHandler } from './helpers/descriptionChangeHandler';

const EditProjectReportModal = ({
  closeModal,
  payload,
}: BaseModalComponentType & {
  payload: ModalNameToModalMap['editProjectReportModal'];
}) => {
  const {
    buttons,
    data,
    editMode,
    employeeData,
    filteredEmployeeData,
    modalTitle,
    selectedTask,
    setIsChangingTask,
    setTimeLogState,
    setToEditMode,
    t,
    timeLogState,
    projectLabel,
  } = useEditProjectReportModal({ payload, closeModal });

  const { projectOptions, handleProjectChange, projectValue } =
    projectSelectHandler({
      employeeData,
      timeLogState,
      setTimeLogState,
    });

  const { taskOptions, taskValue, handleTaskChange } = taskSelectHandler({
    filteredEmployeeData,
    timeLogState,
    setTimeLogState,
    setIsChangingTask,
  });

  const { durationOptions, handleDurationChange, durationValue } =
    durationSelectHandler({
      timeLogState,
      setTimeLogState,
    });

  const handleDescriptionChange = descriptionChangeHandler(setTimeLogState);

  return (
    <div className={styles.container}>
      <Modal
        buttons={buttons}
        closeRight
        onClose={closeModal}
        onTopRight={() => setToEditMode(!editMode)}
        styles={styles}
        title={modalTitle}
        topRightButton={<PenIcon />}
      >
        <form className={styles.form}>
          <div className={styles.form_col}>
            <div className={styles.report_data}>
              <ReportData
                title={t(projectLabel)}
                editMode={editMode}
                value={projectValue}
                onChange={handleProjectChange as ChangeHandler<SelectOption>}
                options={projectOptions}
                readOnlyValue={timeLogState?.project}
                styles={selectPMModalMain as CustomStyles}
              />
            </div>
            <div className={styles.report_data}>
              <ReportData
                title={t('modals.fields.task')}
                editMode={editMode}
                value={taskValue}
                onChange={handleTaskChange as ChangeHandler<SelectOption>}
                options={taskOptions}
                readOnlyValue={timeLogState?.task}
                styles={selectPMModalMain as CustomStyles}
              />
            </div>
            <BillableTypeCheckboxes
              {...{
                timeLogState,
                setTimeLogState,
                editMode,
                dataPairStyles,
                data,
                selectedTask,
              }}
            />
            <div className={styles.report_data}>
              <ReportData
                title={t('modals.fields.duration')}
                editMode={editMode}
                value={durationValue}
                onChange={handleDurationChange as ChangeHandler<SelectOption>}
                options={durationOptions}
                readOnlyValue={timeLogState.duration}
                styles={selectProjectStatus as CustomStyles}
              />
            </div>
            <div className={styles.report_description}>
              <ReportDescription
                title={t('modals.fields.description')}
                editMode={editMode}
                value={timeLogState.note}
                onChange={handleDescriptionChange}
                maxLength={textareaLength}
                styles={styles}
              />
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default withModal(modals.editProjectReportModal)(EditProjectReportModal);
