export const buttonsEN = {
  add_new_client: 'Add new client',
  add_team_member: 'Add team member',
  attach: 'Attach',
  back_to_editing: 'Back to editing',
  book: 'Book',
  cancel: 'Cancel',
  clear: 'Clear',
  continue_editing: 'Continue editing',
  create_project: 'Create project',
  create_report: 'Create report',
  log_employee_time: 'Log employee time',
  delete: 'Delete',
  detailed_view: 'Detailed view',
  dont_save: "Don't save",
  flexible_time_tracking: 'Flexible time tracking',
  new_project: 'New project',
  new_presale: 'New presale',
  new_task: 'New task',
  save: 'Save',
  save_edits: 'Save edits',
  sick_today: 'Sick today',
  submit: 'Submit',
  today: 'Today',
  update: 'Update',
} as const;
