import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { getTimeLogDataForEmployee } from '../../../api/tracking';

import { getEmployeeDataAction } from '../../../store/actionCreators/employeeActionCreators';
import {
  UseEditProjectReportModalProps,
  UseEditProjectReportModalReturn,
} from './types';
import { useUpdateEditedTimelog } from './hooks/useUpdateEditedTimelog';
import { useModalButtons } from './hooks/useButtonBlock';
import { deleteTimeLogHandler } from './helpers/deleteTimeLogHandler';
import { useLocation } from 'react-router-dom';

export const useEditProjectReportModal = ({
  payload,
  closeModal,
}: UseEditProjectReportModalProps): UseEditProjectReportModalReturn => {
  const { t } = useTranslation();
  const { data, employeeId, modalTitle, updateTimeLogList } = payload;
  const { pathname } = useLocation();

  const isPresales = pathname.includes('by-presales');
  const projectLabel = isPresales
    ? 'modals.fields.presale_name'
    : 'modals.fields.project_name';

  const [editMode, setToEditMode] = useState(false);
  const [timeLogState, setTimeLogState] = useState(data);
  const [isChangingTask, setIsChangingTask] = useState(false);

  const dispatch = useAppDispatch();
  const { employeeData } = useAppSelector((state) => state);

  const filteredEmployeeData = employeeData.projectDTOList.find(
    (el) => el?.name === timeLogState.project,
  );

  const selectedTask = filteredEmployeeData?.tasks?.find(
    (x) => x.name === timeLogState.task,
  );

  useEffect(() => {
    const fetchData = async () => {
      if (employeeId) {
        const data = await getTimeLogDataForEmployee({ userId: employeeId });

        if (data) {
          dispatch(getEmployeeDataAction(data));
        }
      }
    };
    fetchData();
  }, [employeeId]);

  useEffect(() => {
    const updateBillableType = () => {
      const billableType = isChangingTask
        ? selectedTask?.billableType
        : timeLogState?.billableType;
      setTimeLogState((prev) => ({ ...prev, billableType }));
    };

    updateBillableType();

    return () => setIsChangingTask(false);
  }, [isChangingTask, selectedTask?.billableType, timeLogState?.billableType]);

  const deleteEditedTimelog = deleteTimeLogHandler(
    updateTimeLogList,
    closeModal,
  );
  const updateEditedTimelog = useUpdateEditedTimelog({
    updateTimeLogList,
    closeModal,
  });

  const buttons = useModalButtons({
    closeModal,
    deleteEditedTimelog,
    editMode,
    t,
    timeLogState,
    updateEditedTimelog,
  });

  return {
    buttons,
    data,
    editMode,
    employeeData,
    filteredEmployeeData,
    modalTitle,
    selectedTask,
    setIsChangingTask,
    setTimeLogState,
    setToEditMode,
    t,
    timeLogState,
    projectLabel,
  };
};
