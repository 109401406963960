import classNames from 'classnames';
import { ReactComponent as CloseXLIcon } from '../../../../icons/CloseXLIcon.svg';
import styles from './BCoinAcievementItemModal.module.scss';
import { BCoinAcievementItemModalProps } from './types';

const BCoinAchievementItemModal = ({
  onClick,
  selectedAchievement,
}: BCoinAcievementItemModalProps) => {
  const isCharity = selectedAchievement.name === 'Charity Machine';

  return (
    <div className={styles.layout}>
      <div className={styles.modalContainer}>
        <div className={styles.mainInfo}>
          <div
            className={classNames(styles.circle, {
              [styles.inactive]: selectedAchievement.times === 0,
            })}
          >
            <img
              src={selectedAchievement?.image ?? '/achievement-icon.png'}
              alt='achievement'
              className={classNames({
                [styles.inactive]: selectedAchievement.times === 0,
              })}
            />
            {isCharity && selectedAchievement.times > 0 && (
              <div className={styles.charityCount}>
                {selectedAchievement.times}
              </div>
            )}
          </div>

          <div className={styles.name}>
            {selectedAchievement.name}
            {!isCharity && selectedAchievement.times > 1 && (
              <span className={styles.count}>
                ({selectedAchievement.times})
              </span>
            )}
          </div>
          <div className={styles.description}>
            {selectedAchievement.description}
          </div>
        </div>

        <div className={styles.rewardInfo}>
          <div className={styles.rewardTitle}>
            {selectedAchievement.times > 0 ? 'you got' : 'you will get'}
          </div>
          <div className={styles.rewardValue}>
            {selectedAchievement.reward}
            <span>b-coins</span>
          </div>
        </div>

        <div className='bcoin-modal-close-btn' onClick={onClick}>
          <CloseXLIcon />
        </div>
      </div>
    </div>
  );
};

export default BCoinAchievementItemModal;
