import { NavigateOptions, useNavigate } from 'react-router-dom';
export interface RowPropsForItemDetails {
  original: {
    id: number;
  };
}
const useInspectItem = (
  locationState: NavigateOptions['state'],
  url: string,
) => {
  const navigate = useNavigate();

  const goToItemDetails = (row: RowPropsForItemDetails) => {
    navigate(`${url}${row.original.id}`, { state: locationState });
  };

  return {
    goToItemDetails,
  };
};

export default useInspectItem;
