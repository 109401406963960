import { FC } from 'react';
import styles from './Achievements.module.scss';
import { AchievementProps, AchievementsProps } from '../../types';
import classNames from 'classnames';

const Achievement: FC<AchievementProps> = ({
  achievement,
  toggleViewAchievementItem,
}) => {
  const isCompleted = achievement.times > 0;
  const shouldShowCount = achievement.times > 1;
  const isCharity = achievement.name === 'Charity Machine';

  return (
    <div
      className={styles.achievementItem}
      onClick={() => toggleViewAchievementItem(achievement)}
    >
      <div
        className={classNames(styles.circle, {
          [styles.inactive]: !isCompleted,
        })}
      >
        <img
          src={achievement.image ?? '/achievement-icon.png'}
          alt='achievement'
          className={classNames({
            [styles.inactive]: !isCompleted,
          })}
        />
        {isCharity && isCompleted && (
          <div className={styles.charityCount}>{achievement.times}</div>
        )}
      </div>
      <div className={styles.name}>
        {achievement.name}
        {!isCharity && shouldShowCount && (
          <span className={styles.count}>({achievement.times})</span>
        )}
      </div>
    </div>
  );
};

const Achievements: FC<AchievementsProps> = ({
  achievements,
  toggleViewAchievementItem,
}) => {
  const sortedAchievements = [...achievements].sort((a, b) => {
    if (a.times > 0 && b.times === 0) return -1;
    if (a.times === 0 && b.times > 0) return 1;
    return 0;
  });

  return (
    <div className={styles.root}>
      {sortedAchievements.map((achievement) => (
        <Achievement
          key={achievement.id}
          achievement={achievement}
          toggleViewAchievementItem={toggleViewAchievementItem}
        />
      ))}
    </div>
  );
};

export default Achievements;
