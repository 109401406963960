import { ReactComponent as ClientsIcon } from './../icons/ClientsIcon.svg';
import { ReactComponent as ProjectsIcon } from './../icons/ProjectsIcon.svg';
import { ReactComponent as PresalesIcon } from './../icons/PresalesIcon.svg';
import { ReactComponent as TasksIcon } from './../icons/TasksIcon.svg';
import { ReactComponent as ReportsIcon } from './../icons/ReportsIcon.svg';
import { ReactComponent as SicknessIcon } from './../icons/SicknessIcon.svg';
import { ReactComponent as TimeTrackingIcon } from './../icons/TimeTrackingIcon.svg';
import { ReactComponent as VacationIcon } from './../icons/VacationIcon.svg';
import { ReactComponent as BCoinIcon } from './../icons/BCoinIcon.svg';
import { ConfigsArray, ConfigsProps } from './types';
import React from 'react';

const configDefinitions = [
  {
    id: 'bcoin-icon',
    title: 'menu.bcoin',
    isEmployeeSpecific: true,
    icon: <BCoinIcon />,
  },
  {
    id: 'tracking-icon',
    title: 'menu.time_tracking',
    isEmployeeSpecific: false,
    icon: <TimeTrackingIcon />,
  },
  {
    id: 'sickness-icon',
    title: 'menu.sickness',
    isEmployeeSpecific: true,
    icon: <SicknessIcon />,
  },
  {
    id: 'vacation-icon',
    title: 'menu.vacation',
    isEmployeeSpecific: true,
    icon: <VacationIcon />,
  },
  {
    id: 'projects-icon',
    title: 'menu.projects',
    isEmployeeSpecific: false,
    icon: <ProjectsIcon />,
  },
  {
    id: 'presales-icon',
    title: 'menu.presales',
    isEmployeeSpecific: false,
    icon: <PresalesIcon />,
  },
  {
    id: 'clients-icon',
    title: 'menu.clients',
    isEmployeeSpecific: false,
    icon: <ClientsIcon />,
  },
  {
    id: 'tasks-icon',
    title: 'menu.tasks',
    isEmployeeSpecific: false,
    icon: <TasksIcon />,
  },
  {
    id: 'reports-icon',
    title: 'menu.reports',
    isEmployeeSpecific: false,
    icon: <ReportsIcon />,
  },
];

export const createManagerConfigs = ({
  isEmployee,
  t,
}: ConfigsProps): ConfigsArray => {
  return configDefinitions
    .filter((config) => isEmployee || !config.isEmployeeSpecific)
    .map(({ id, title, icon }) => ({
      id,
      icon,
      title: t(title),
    }));
};
