import { ModalButton, UseModalButtonsProps } from '../types';

export const useModalButtons = ({
  t,
  onSave,
  projectState,
  onClose,
  isPresale,
}: UseModalButtonsProps) => {
  const modalButtons: ModalButton[] = [
    {
      id: '1',
      label: t('buttons.save') as string,
      className: 'btn__rounded-blue',
      save: () => onSave(projectState),
      disabled:
        !projectState.projectName || (!isPresale && !projectState.customerName),
      type: 'submit',
    },
    {
      id: '2',
      label: t('buttons.cancel') as string,
      className: 'btn__string--normal--grey',
      close: onClose,
    },
  ];

  return modalButtons;
};
