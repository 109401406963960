import { formatDateYYYYMMDD } from '../../../../utils/date-formatting';
import { UseStartEndDateProps, UseStartEndDateReturn } from '../types';

export const useStartEndDate = ({
  projectData,
  setTeamMemberState,
  teamMemberState,
}: UseStartEndDateProps): UseStartEndDateReturn => {
  const handleStartDateChange = (selected: Date) =>
    setTeamMemberState((state) => ({
      ...teamMemberState,
      startDate: selected ? formatDateYYYYMMDD(selected) : state.startDate,
    }));

  const minStartDate = new Date(projectData?.startDate ?? 0);

  const startDate = teamMemberState?.startDate
    ? new Date(
        new Date(teamMemberState.startDate).setMinutes(
          new Date(teamMemberState.startDate).getTimezoneOffset(),
        ),
      )
    : undefined;

  const handleEndDateChange = (selected: Date) =>
    setTeamMemberState((state) => ({
      ...teamMemberState,
      endDate: selected ? formatDateYYYYMMDD(selected) : state.endDate,
    }));

  const minEndDate = new Date(teamMemberState.startDate ?? 0);

  const endDate = teamMemberState?.endDate
    ? new Date(
        new Date(teamMemberState.endDate).setMinutes(
          new Date(teamMemberState.endDate).getTimezoneOffset(),
        ),
      )
    : null;

  return {
    endDate,
    handleEndDateChange,
    handleStartDateChange,
    minEndDate,
    minStartDate,
    startDate,
  };
};
