import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserNameIdDto, ProjectDto } from '../../../api/api-types';
import { fetchEmployeesByRole } from '../../../api/team';
import { restAccrualTypes } from '../../../constants/select-values';
import { durationHours, durationMinutes } from '../../../utils/createDuration';

import { AccrualFilterSingular } from '../../../translations/accrualTypeFilterEN';
import { FormValues, UseAddEmployeeTimeLogModalReturn } from './types';
import { initialFormValues } from './constants';
import { useOnEmployeeChange } from './hooks/useOnEmployeeChange';
import { useOnTaskChange } from './hooks/useOnTaskChange';
import { useOnSubmitHandler } from './hooks/useOnSubmitHandler';
import { getEmployeeOptions } from './helpers/getEmployeeOptions';
import { getProjectOpitons } from './helpers/getProjectOptions';

export const useAddEmployeeTimeLogModal = ({
  closeModal,
}: {
  closeModal: () => void;
}): UseAddEmployeeTimeLogModalReturn => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState<FormValues>(
    initialFormValues as FormValues,
  );
  const [employees, setEmployees] = useState<UserNameIdDto[]>([]);
  const [timeLogs, setTimeLogs] = useState<ProjectDto[]>([]);

  const hours = durationHours();
  const minutes = durationMinutes();
  const accrualTypeOptions = restAccrualTypes(t).map((option) => ({
    ...option,
  }));

  const employeeProjectTasks = timeLogs.find(
    ({ name }) => name === formValues.projectName,
  )?.tasks;

  const employeeOptions = getEmployeeOptions(employees);
  const projectOptions = getProjectOpitons(timeLogs);

  const taskOptions =
    employeeProjectTasks?.map(({ eptId, name }) => ({
      label: name || '',
      value: eptId || null,
    })) || [];

  const isSubmitDisabled = Object.entries(formValues).some(
    ([key, value]) => key !== 'comment' && !value,
  );

  const onEmployeeChange = useOnEmployeeChange({
    setTimeLogs,
    setFormValues,
    formValues,
  });

  const onProjectChange = async (projectName: string) => {
    setFormValues({
      ...formValues,
      accrualType: '',
      projectName,
      taskId: null,
    });
  };

  const onTaskChange = useOnTaskChange({
    employeeProjectTasks,
    formValues,
    setFormValues,
  });

  const onAccrualTypeChange = (type: AccrualFilterSingular | '') => {
    setFormValues({ ...formValues, accrualType: type });
  };

  const { onSubmit, isSubmitting } = useOnSubmitHandler({
    formValues,
    closeModal,
  });

  useEffect(() => {
    async function fetchAndSetEmployees() {
      const res = await fetchEmployeesByRole();

      setEmployees(res || []);
    }

    fetchAndSetEmployees();
  }, []);

  return {
    accrualTypeOptions,
    employeeOptions,
    formValues,
    hours,
    isSubmitDisabled,
    isSubmitting,
    minutes,
    onAccrualTypeChange,
    onEmployeeChange,
    onProjectChange,
    onSubmit,
    onTaskChange,
    projectOptions,
    setFormValues,
    t,
    taskOptions,
  };
};
