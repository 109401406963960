import { z } from 'zod';
import { ACCRUAL_FILTER_TYPE_VALUES } from '../translations/accrualTypeFilterEN';

export const CreateProjectSchema = z.object({
  description: z.string(),
  endDate: z.string().optional(),
  startDate: z.string().optional(),
  isIncludedInPlanning: z.boolean(),
  projectManagerId: z.number().optional(),
  projectName: z
    .string({ required_error: 'Project Name is required' })
    .min(1, { message: 'Project Name is required' }),
  billableType: z.enum(ACCRUAL_FILTER_TYPE_VALUES),
  customerId: z.number().optional(),
  customerName: z.string({ required_error: 'Customer name is required' }),
  isPresale: z.boolean() || z.null(),
});

export type CreateProject = z.infer<typeof CreateProjectSchema>;
