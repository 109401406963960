import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useSortDataTable from '../../hooks/useSortDataTable';
import { sortByDefault } from '../../utils/filtering';
import useInspectItem from '../../hooks/useInspectItem';
import { PageProjectWebDto } from '../../api/api-types';
import { useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { reducer, valuesInitialState } from '../../reducer/reducer';
import { UseProjectListPageReturn } from './types';
import { RootState } from '../../store';

export const useProjectListPage = ({
  isPresale,
}: {
  isPresale: boolean | null;
}): UseProjectListPageReturn => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const { sortParam, toggleSortParam } = useSortDataTable('name');

  const goToItemLocation = isPresale ? 'presales' : 'projects';
  const goToItemURL = isPresale ? '/presales/' : '/projects/';

  const { goToItemDetails } = useInspectItem(goToItemLocation, goToItemURL);

  const {
    locationList,
    pmList,
    projectList,
    projectListAll,
    registeredEmployee,
  } = useAppSelector((state: RootState) => state);

  const [handleValues, setHandleValues] = useReducer(
    reducer,
    valuesInitialState(t),
  );

  const {
    pageData,
    projectId,
    projectName,
    selectedPMId,
    selectedPMName,
    selectedStatus,
    toggleAddProject,
  } = handleValues;

  const { totalAmount } = pageData;
  const isActiveStatus = selectedStatus === t('status.value.0');

  const getIsActiveProject = (
    startDate: string | undefined,
    endDate: string | undefined,
  ) => {
    const today = new Date().setHours(0, 0, 0, 0);

    const start = startDate
      ? new Date(startDate).setHours(0, 0, 0, 0)
      : undefined;
    const end = endDate ? new Date(endDate).setHours(0, 0, 0, 0) : undefined;

    if (start && !end) {
      return start <= today;
    } else if (!start && end) {
      return today <= end;
    } else if (start && end) {
      return start <= today && today <= end;
    }

    return !start && !end;
  };

  const projectListAllSorted = [...projectListAll].sort((a, b) =>
    sortByDefault(a, b, 'projectName'),
  );

  const getFilterdProjectList = () => {
    if (selectedPMName === t('projects.listing.value.2')) {
      return projectListAllSorted.filter(
        ({ startDate, endDate, managerIdAndName }) =>
          getIsActiveProject(startDate, endDate) === isActiveStatus &&
          !managerIdAndName,
      );
    }
    if (selectedPMId !== '') {
      return projectListAllSorted.filter(
        ({ startDate, endDate, managerIdAndName }) =>
          getIsActiveProject(startDate, endDate) === isActiveStatus &&
          selectedPMId === managerIdAndName?.id,
      );
    }
    return projectListAllSorted.filter(
      ({ startDate, endDate }) =>
        getIsActiveProject(startDate, endDate) === isActiveStatus,
    );
  };

  const setPageCurrentData = (data: PageProjectWebDto) => {
    setHandleValues({
      type: 'SET_PAGE_DATA',
      payload: {
        pageData: {
          totalAmount: data.totalElements,
          projectList: data.content,
        },
      },
    });
  };

  return {
    currentPage,
    dispatch,
    getFilterdProjectList,
    goToItemDetails,
    isLoading,
    locationList,
    pmList,
    projectId,
    projectList,
    projectListAll,
    projectListAllSorted,
    projectName,
    registeredEmployee,
    selectedPMId,
    selectedPMName,
    selectedStatus,
    setCurrentPage,
    setHandleValues,
    setIsLoading,
    setPageCurrentData,
    sortParam,
    toggleAddProject,
    toggleSortParam,
    totalAmount,
  };
};
