import React from 'react';

import { withModal } from '../../../providers/ModalProvider';
import Modal from '../Modal';
import Button from '../../Button/Button';
import SimpleSelect from '../../Select/SimpleSelect';
import CalendarDatePicker from '../../DatePickers/DatePicker/CalendarDatePicker';
import TextAreaField from '../../TextAreaField/TextAreaField';
import { ReactComponent as CancelIcon } from '../../../icons/CancelIcon.svg';

import { BaseModalComponentType } from '../types';

import modals from '../../../constants/modals';
import { textareaLength } from '../../../constants/input-restrictions';

import styles from './../styles/add-employee-timelog.module.scss';
import { selectCustomStyles } from './constants';
import { useAddEmployeeTimeLogModal } from './useAddEmployeeTimeLogModal';
import { AccrualFilterSingular } from '../../../translations/accrualTypeFilterEN';

const AddEmployeeTimeLog = ({ closeModal }: BaseModalComponentType) => {
  const {
    accrualTypeOptions,
    employeeOptions,
    formValues,
    hours,
    isSubmitDisabled,
    isSubmitting,
    minutes,
    onAccrualTypeChange,
    onEmployeeChange,
    onProjectChange,
    onSubmit,
    onTaskChange,
    projectOptions,
    setFormValues,
    t,
    taskOptions,
  } = useAddEmployeeTimeLogModal({ closeModal });

  return (
    <Modal
      styles={styles}
      title='Add log time for an employee'
      onClose={closeModal}
      closeRight
      topRightButton={<CancelIcon />}
      onTopRight={closeModal}
    >
      <form onSubmit={onSubmit} className={styles.modal_form}>
        <div className={styles.modal_form_fields}>
          <SimpleSelect
            isMulti={false}
            key={t('reports.filters.employee_name')}
            selectWrapperClass={`select_vertical_wrapper ${styles.modal_form_select_wrapper}`}
            selectTitleClassName='select_title'
            placeholder='Select name'
            selectTitle={t('reports.filters.employee_name') as string}
            value={employeeOptions.find(
              ({ value }) => value === formValues.employeeId,
            )}
            options={employeeOptions}
            onChange={(e) => onEmployeeChange(Number(e?.value))}
            styles={selectCustomStyles}
          />
          <SimpleSelect
            isMulti={false}
            selectWrapperClass={`select_vertical_wrapper ${styles.modal_form_select_wrapper}`}
            selectTitleClassName='select_title'
            selectTitle={`${t('form.project')}`}
            value={{
              label: formValues.projectName || 'Select project',
              value: formValues.projectName || '',
            }}
            onChange={(e) => onProjectChange(e?.value || '')}
            options={projectOptions}
            isDisabled={!formValues.employeeId}
            styles={selectCustomStyles}
          />
          <SimpleSelect
            isMulti={false}
            selectWrapperClass={`select_vertical_wrapper ${styles.modal_form_select_wrapper}`}
            selectTitleClassName='select_title'
            selectTitle={`${t('form.task')}`}
            value={
              taskOptions.find(({ value }) => value === formValues.taskId) || {
                label: 'Select',
                value: null,
              }
            }
            onChange={(e) => onTaskChange(e?.value || 0)}
            options={taskOptions}
            isDisabled={!formValues.projectName}
            styles={selectCustomStyles}
          />
          <div className={styles.modal_form_fields_date_type}>
            <CalendarDatePicker
              key={t('reports.filters.date')}
              wrapperClassName='reports__date--wrapper'
              inputClassName='reports__date--input'
              titleClassName='reports__date--title'
              title={t('reports.filters.date') as string}
              placeholderText='Select date'
              onChange={(date) => {
                if (date)
                  setFormValues({ ...formValues, date: date.toUTCString() });
              }}
              startDate={new Date(formValues.date)}
              maxDate={new Date()}
            />
            <SimpleSelect
              isMulti={false}
              selectWrapperClass={`select_vertical_wrapper ${styles.modal_form_select_wrapper}`}
              selectTitleClassName='select_title'
              selectTitle={`${t('form.billable_type')}`}
              value={
                accrualTypeOptions.find(
                  ({ value }) => value === formValues.accrualType,
                ) || {
                  label: 'Select',
                  value: '' as const,
                }
              }
              onChange={(e) =>
                onAccrualTypeChange(e?.value as AccrualFilterSingular)
              }
              options={accrualTypeOptions}
              isDisabled={!formValues.taskId}
              styles={selectCustomStyles}
            />
          </div>
          <div className={styles.modal_form_fields_time}>
            <SimpleSelect
              isMulti={false}
              selectWrapperClass={`select_vertical_wrapper ${styles.modal_form_select_wrapper}`}
              selectTitleClassName='select_title'
              selectTitle={`${t('form.duration')}*`}
              options={hours}
              value={{
                label: formValues.hours || 'hh',
                value: formValues.hours || 'hh',
              }}
              onChange={(e) => {
                setFormValues({ ...formValues, hours: e?.value || '' });
              }}
              isOptionDisabled={(option) =>
                formValues.minutes === '00' && option.label === '00'
              }
              styles={selectCustomStyles}
              menuPlacement='top'
            />
            <SimpleSelect
              isMulti={false}
              selectWrapperClass={`select_vertical_wrapper ${styles.modal_form_select_wrapper}`}
              options={minutes}
              value={{
                label: formValues.minutes || 'mm',
                value: formValues.minutes || 'mm',
              }}
              isOptionDisabled={(option) =>
                formValues.hours === '00' && option.label === '00'
              }
              onChange={(e) => {
                setFormValues({ ...formValues, minutes: e?.value || '' });
              }}
              styles={selectCustomStyles}
              isDisabled={!formValues.hours}
              menuPlacement='top'
            />
          </div>
        </div>
        <div className={styles.modal_form_text_area}>
          <TextAreaField
            fieldClassName={styles.modal_form_text_area_field}
            titleClassName={styles.modal_form_text_area_field_title}
            className={styles.textAreaLabel}
            maxLength={textareaLength}
            title={t('form.note') as string}
            value={formValues.comment}
            onChange={(e) =>
              setFormValues({ ...formValues, comment: e.target.value })
            }
            helperText={`${textareaLength - formValues.comment.length} ${t(
              'form.characters_left',
            )}`}
            helperTextClassName={styles.modal_form_text_area_field_title}
          />
          <Button
            type='submit'
            label='Submit'
            className={`btn__rounded-blue ${styles.modal_form_submit}`}
            disabled={isSubmitDisabled || isSubmitting}
          />
        </div>
      </form>
    </Modal>
  );
};

export default withModal(modals.addEmployeeTimeLogModal)(AddEmployeeTimeLog);
