import React from 'react';

import './pm-project-info.scss';
import { useTranslation } from 'react-i18next';
import { ProjectWebDto } from '../../api/api-types';

type PMProjectInfoProps = {
  data: ProjectWebDto;
  managerTitle?: string;
};

const PMProjectInfo = (props: PMProjectInfoProps) => {
  const { t } = useTranslation();
  const { data, managerTitle } = props;

  return (
    <div className='pm-info'>
      <div className='pm-info__text'>
        <span>{managerTitle}</span>
        {data?.managerIdAndName?.name || '-'}
      </div>
      <div className='pm-info__text'>
        <span>{t('item_info.start_date')}</span>
        {data?.startDate || '-'}
      </div>
      <div className='pm-info__text'></div>
      <div className='pm-info__text'>
        <span>{t('item_info.description')}</span>
        {data.description || '-'}
      </div>
    </div>
  );
};

export default PMProjectInfo;
