import ExpandableTable from '../../components/Tables/ExpandableTable';
import { totalReportColumns } from '../../modules/Reports/columns';
import EmptyDataBlock from '../../modules/Reports/EmptyDataBlock';
import Loader from '../../components/Loader/Loader';
import { sortByName } from '../../constants/sort-order';
import { useReportPresalesEmployee } from './useReportPresalesEmployee';

const ReportPresalesEmployee = () => {
  const {
    expandByDefault,
    expandedRowStyle,
    goToEmployee,
    isLoading,
    setExpandedRowId,
    sortedPresalesEmployeeReportTableData,
    t,
    tableRef,
  } = useReportPresalesEmployee();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {sortedPresalesEmployeeReportTableData?.length ? (
        <ExpandableTable
          columns={totalReportColumns(t)}
          data={sortedPresalesEmployeeReportTableData}
          expandedRowId={expandByDefault}
          expandedRowKey='expandedEmployee'
          expandedRowStyle={expandedRowStyle}
          onRowClick={goToEmployee}
          ref={tableRef}
          rowDepth={0}
          setExpandedRowId={setExpandedRowId}
          sortOrder={sortByName}
          tableName='total-reports-list'
        />
      ) : (
        <EmptyDataBlock />
      )}
    </>
  );
};

export default ReportPresalesEmployee;
