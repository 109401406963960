import ReportTable from '../../components/Tables/ReportTable';
import EmptyDataBlock from '../../modules/Reports/EmptyDataBlock';
import { displayBillable } from '../../utils/conditionalRowStyles';
import Loader from '../../components/Loader/Loader';
import {
  employeeReport5Col,
  employeeReport6Col,
} from '../../modules/Reports/columns';
import { useReportProjectEmployee } from './useReportProjectEmployee';
import Row from '../../components/Row/Row';
import EmployeeReportName from './components/EmployeeReportName';

const ReportProjectEmployee = () => {
  const {
    editTimeLogDetails,
    isInternalAdministration,
    isLoading,
    reportProjectEmployee,
    t,
    tableRef,
  } = useReportProjectEmployee();

  if (isLoading) return <Loader />;

  return (
    <>
      {reportProjectEmployee?.timeLogs?.length ? (
        <>
          <EmployeeReportName
            taskName={reportProjectEmployee.timeLogs[0].taskName}
            employeeFullName={reportProjectEmployee.employeeFullNameAndId}
          />

          <ReportTable
            changeRowColor={displayBillable}
            columns={
              isInternalAdministration
                ? employeeReport6Col(t)
                : employeeReport5Col(t)
            }
            data={reportProjectEmployee.timeLogs}
            onRowClick={editTimeLogDetails}
            ref={tableRef}
            tableName={
              isInternalAdministration
                ? 'pm-project-employee-list'
                : 'pm-employee-list'
            }
          />

          <Row
            label={t('reports.subtitle.sub_total')}
            rowClass={'sub_total'}
            value={reportProjectEmployee.subTotal}
          />
        </>
      ) : (
        <EmptyDataBlock />
      )}
    </>
  );
};

export default ReportProjectEmployee;
